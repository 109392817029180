.header-compact {
    position: fixed;
    padding: 12px;
    background-color: white;
    z-index: 9999;
    svg {
        align-self: center;
        max-width: 30%;
    }

    .menu-wrapper {
        position: absolute;
        background-color: white;
        right: 0;
        top: 25px;
        right: 24px;
        bottom: 0;
        margin: auto;
        z-index: 999;

        label {
            width: 48px;
            height: 48px;
        }

        #menu-expand {
            visibility: hidden;
        }

        .hamburger {
            position: absolute;
            width: 30px;
            height: 3px;
            right: 0;
            top: 12px;
            transition: .25s;
            z-index: 999;
            background-color: white;
        }

        .hamburger::before {
            position: absolute;
            content: "";
            display: block;
            background-color: inherit;
            width: 30px;
            height: 3px;
            transition: .25s ease-in-out;
            margin-top: 12px;
            background-color: black;
        }


        .hamburger::after {
            position: absolute;
            content: "";
            bottom: 3px;
            display: block;
            background-color: inherit;
            width: 30px;
            height: 3px;
            transition: .25s ease-in-out;
            margin-top: 12px;
            background-color: black;
        }



        ul {
            display: flex;
            align-items: center;
            margin: 0px;
            height: 100%;
            list-style-type: none;
            li {
                margin-right: 40px;
                text-decoration: none;
                text-transform: none;
                font-weight: 600;
                 a {
                     text-decoration: none;
                     text-transform: none;
                     color: #20252a;
                     font-weight: 700;
                     font-size: 16px;
                     line-height: 24px;
                     letter-spacing: 0.51px;
                 }
    
                 a:hover {
                     color: $btn-primary;
                 }
            }
        }

    }
}
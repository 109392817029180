@import './colors.scss';
@import './fonts.scss';

.whytobepartner {
    padding: 64px 0px;
    font-family: $font-title-secondary;
    background-color: white;



    h1 {
        font-size: 32px;
        line-height: 42px;
        color: $tertiary;
        font-weight: 600;
        position: relative;
        margin-bottom: 24px;
    }

    h5 {
        color: #212121;
    }

    .icon-wrapper {
            background-color: transparent;
            height: 48px !important;
            width: 48px !important;
            border-radius: 48px;
            display: flex;
            align-items: center;
            padding: 12px;
            justify-content: center;

    }

    p {
        margin: 0px;
        padding: 0px;
        margin-left: 12px;
        color: #5A5A71;
        width: fit-content;
        font-weight: medium;
    }

    img {
        max-width: 50%;
        margin-top: 60px;
    }

    h4 {
        color: #4F6678;
        text-transform: uppercase;
        font-size: 14px;
    }
}
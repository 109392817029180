@import './colors.scss';

.partners-container {
    background-color:  $tertiary;
    padding: 64px 32px;

    h4 {
        color: #c4c4c4;
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 18px;
    }

    h1 {
        color: $background2;
    }

    .btn-full {
        @media screen and (max-width: 666px) {
            
        }
    }

    .btn-compacted {
        @media screen and (min-width: 667px) {
            display: none !important;
        }
    }
}
@import './colors';
@import './fonts.scss';

.headerV2 {
    font-family: $font-secondary;
    position: fixed;
    width: 100vw;
    font-weight: 400px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    height: 8vh;
    z-index: 999;
    display: flex;
    align-items: center;
    background-color: white;

    .logo-wrapper {
        max-width: fit-content;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .menu-wrapper {
        display: flex;
        align-items: center;
        ul {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0px;
            margin: 0px;

            li {
                list-style: none;
                margin-right: 24px;
                color: $tertiary;

                a {
                    text-decoration: none;
                    color: $tertiary;
                }

                svg {
                    margin-left: 8px;
                }

                .soon {
                    position: absolute;
                    top: 24px;
                    right: 0px;
                    margin: auto;
                    background-color: #0D2C77;
                    text-align: center;
                    border-radius: 4px;
                    font-size: 8px;
                    text-transform: uppercase;
                    width: fit-content;
                    padding: 2px 8px;
                    font-weight: bold;
                    z-index: 2;
                    color: white;
                    }
                }


            .download-app {
                position: relative;
                transition: all .250s ease-in-out;

                .hidden-menu {
                    padding: 24px;
                    background-color: #0D2C77;
                    position: absolute;
                    visibility: hidden;
                    left: -150px;
                    top: 20px;
                    border-radius: 0px 0px 10px 10px;
                    width: 400px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: $background2;
                    opacity: 0;
                    z-index: 20;


                    svg {
                        margin-bottom: 24px;
                    }

                    
                }
            }

            .download-app:hover {
                .hidden-menu {
                    visibility: visible;
                    transition: all .450s ease-in-out;
                    opacity: 1;
                }
            }

        }
    }

    .cta-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin: 0px;
            padding: 0px;
        }

        button {
            margin-left: 24px;
            background-color: $primary;
            border: none;
            height: 44px;
            padding: 0px 24px;
            border-radius: 44px;
            color: white;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 8px;
            }
        }
    }
}
@import './colors.scss';

.princing-container {
    padding: 64px 0px;
    background-color: $background5;
    flex-wrap: wrap;

    .title {
        text-align: center;
        font-size: 36px;
        line-height: 50px;
        color: rgb(42, 42, 59);
        font-weight: 600;
        position: relative;
        margin-bottom: 64px;
    }

    .title:before {
        content: '';
        display: block;
        margin: auto;
        height: 3px;
        width: 64px;
        background-color: $tertiary;
    }

    .promotional-text-wrapper {
        :nth-child(1) {
            font-size: 32px;
            line-height: 46px;
            text-align: center;
            max-width: 426px;
            color: rgb(43, 43, 43);
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;

            strong {
                color:#2D7EFC;
            }
        }

        :nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            padding-bottom: 50px;
            text-align: center;
            width: 100%;
        }
    }


    svg {
        max-width: 500px;
    }
}

.cards-wrapper {
    position: relative;
}
.cards {
    display: flex;
    position: absolute;
    left: 60px;
    top: -15px;
    margin-left: 60px;
    top: -15px;
    align-items: center;

    @media screen and (max-width: 1300px) {
        position: relative !important;
      }

    .card-primary {
        box-shadow: rgb(69 69 80 / 20%) 8px 8px 32px;
        background-color: white;
        width: 250px;
        border-radius: 8px;
        z-index: 999;
    }

    .card-secondary {
        background-color: white;
        width: 250px;
        height: fit-content;
        border-radius: 8px;
    }

    h1 {
        display: block;
        width: 100%;
        padding: 15px 0px;
        border-bottom: 1px solid rgb(242, 242, 243);
        color: rgb(139, 139, 146);
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        letter-spacing: 0.24em;
        font-weight: 500;
    }

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        color: $tertiary;
        text-align: center;
    }

    p {
        margin-bottom: 12px;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: rgb(69, 69, 80);
    }

    .item-container {
        padding: 24px;
        .item-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .item-marker {
                width: 4px;
                height: 4px;
                border: 1px solid $primary;
                border-radius: 2px;
            }
            p {
                margin: 0px;
                padding: 0px;
                margin-left: 8px;
                font-weight: 500;
            }
        }
    }

  
}

.card-mobile {
    .card-primary {
        box-shadow: rgb(69 69 80 / 20%) 8px 8px 32px;
        background-color: white;
        width: 250px;
        border-radius: 8px;
        z-index: 999;
    }

    .card-secondary {
        background-color: white;
        width: 250px;
        height: fit-content;
        border-radius: 8px;
    }

    h1 {
        display: block;
        width: 100%;
        padding: 15px 0px;
        border-bottom: 1px solid rgb(242, 242, 243);
        color: rgb(139, 139, 146);
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        letter-spacing: 0.24em;
        font-weight: 500;
    }

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        color: $tertiary;
        text-align: center;
    }

    p {
        margin-bottom: 12px;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: rgb(69, 69, 80);
    }

    .item-container {
        padding: 24px;
        .item-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .item-marker {
                width: 4px;
                height: 4px;
                border: 1px solid $primary;
                border-radius: 2px;
            }
            p {
                margin: 0px;
                padding: 0px;
                margin-left: 8px;
                font-weight: 500;
            }
        }
    }
}
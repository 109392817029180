.banner-container {
    margin-top: 32px;
    .banner-img {
        margin-top: 32px;
        width: 100%;
    }
    margin-bottom: 60px;

    @media screen and (max-width: 991px) {
        margin-bottom: 32px;
    }
}
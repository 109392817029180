@import './colors.scss';

.login {

    height: 100vh;

    .situation-text-error {
        background-color: #ec426d3a;
        color: #20252a;
        padding: 12px;
        border-radius: 4px;
    }

    .left-content {
        background-color: $primary; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
        }
        
        .phrase-container {
            max-width: 60%;
            height: 35vh;
            align-self: center;
            display: flex;
            margin-top: 15vh;
            h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 57px;
                line-height: 67px;
                color: white;
                text-align: start;
            }

            @media screen and (max-width: 1600px){
                h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 48px;
                line-height: 58px;
                color: white;
                }
            }
        }

        .background-img-container {
            padding: 0px;
            margin: 0px;
            max-height: 40vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                overflow: hidden !important;
                max-width: 100%;
                max-height: 100%;
                bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;
                left: auto;
                right: auto;
            }
        }

        @media screen and (max-width: 1300px) {
            position: relative !important;
        }

    }

    .right-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h5 {
            text-align: center;
        }

        svg {
            margin-bottom: 64px;
        }

        .text-accent {
            color: #617889;
            margin-top: 24px;
            max-width: 400px;
            text-align: center;
        }

        .input-wrapper {
            margin-top: 64px;
            width: 100%;
            max-width: 450px;
            display: flex;
            flex-direction: column;



            button {
                margin-top: 24px;
                background-color:$primary;
                color: white;
                border-radius: 8px;
                height: 40px;
                border: none;
            }

            label {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 24px;

                input {
                    width: 100%;
                    height: 40px;
                    border-radius: 4px;
    
                    outline: none;
                    background-color: transparent;
                    border: 2px solid #E1E8ED;
                    padding: 0px 8px;
                    color: $tertiary;
                }

                p {
                    margin: 0px;
                    padding: 0px;
                    color: #0D2C77;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-bottom: 8px;
                    letter-spacing: 1.2px;
                    font-weight: 600;

                }

                .forgot-password {
                    color: $tertiary;
                    text-decoration: underline;
                }
            }
        }
    }

    
}
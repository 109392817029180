@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.title-internal {
  font-size: 32px !important;
  margin-bottom: 42px;
}

@font-face {
  font-family: "card-font";
  src: url("../fonts/nokio-slab-regular.otf");
}
.header-desktop-container {
  height: 15vh;
  padding-top: 31px;
  transition: all 2s;
  position: relative;
}

.header-desktop-container-relative {
  transition: all 1s;
  z-index: 10000;
  height: fit-content;
  background-color: white;
  padding: 12px 0px;
  opacity: 1;
}

.hide-menu {
  top: -500px;
  transition: all 1s;
  z-index: 10000;
  height: fit-content;
  background-color: white;
  padding: 12px 0px;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.show-menu {
  top: 0;
  transition: all 0.4s ease-in-out;
}

.logo-wrapper {
  height: 100%;
  margin-right: 42px;
  cursor: pointer;
}

.nav-menu-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.nav-menu-wrapper ul {
  display: flex;
  align-items: center;
  margin: 0px;
  height: 100%;
  list-style-type: none;
}
.nav-menu-wrapper ul li {
  margin-right: 40px;
  text-decoration: none;
  text-transform: none;
  font-weight: 600;
}
.nav-menu-wrapper ul li a {
  text-decoration: none;
  text-transform: none;
  color: #20252a;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.51px;
}
.nav-menu-wrapper ul li a:hover {
  color: #1DA1F2;
}

.header-compact {
  position: fixed;
  padding: 12px;
  background-color: white;
  z-index: 9999;
}
.header-compact svg {
  align-self: center;
  max-width: 30%;
}
.header-compact .menu-wrapper {
  position: absolute;
  background-color: white;
  right: 0;
  top: 25px;
  right: 24px;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.header-compact .menu-wrapper label {
  width: 48px;
  height: 48px;
}
.header-compact .menu-wrapper #menu-expand {
  visibility: hidden;
}
.header-compact .menu-wrapper .hamburger {
  position: absolute;
  width: 30px;
  height: 3px;
  right: 0;
  top: 12px;
  transition: 0.25s;
  z-index: 999;
  background-color: white;
}
.header-compact .menu-wrapper .hamburger::before {
  position: absolute;
  content: "";
  display: block;
  background-color: inherit;
  width: 30px;
  height: 3px;
  transition: 0.25s ease-in-out;
  margin-top: 12px;
  background-color: black;
}
.header-compact .menu-wrapper .hamburger::after {
  position: absolute;
  content: "";
  bottom: 3px;
  display: block;
  background-color: inherit;
  width: 30px;
  height: 3px;
  transition: 0.25s ease-in-out;
  margin-top: 12px;
  background-color: black;
}
.header-compact .menu-wrapper ul {
  display: flex;
  align-items: center;
  margin: 0px;
  height: 100%;
  list-style-type: none;
}
.header-compact .menu-wrapper ul li {
  margin-right: 40px;
  text-decoration: none;
  text-transform: none;
  font-weight: 600;
}
.header-compact .menu-wrapper ul li a {
  text-decoration: none;
  text-transform: none;
  color: #20252a;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.51px;
}
.header-compact .menu-wrapper ul li a:hover {
  color: #1DA1F2;
}

.mobile-menu-area {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
  z-index: 100000000;
}
.mobile-menu-area .divisor-left {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
}
.mobile-menu-area .menu-area {
  overflow-y: scroll;
  background-color: rgb(0, 15, 64);
  padding: 24px 24px 42px 24px;
  height: fit-content;
  width: 100vw;
  animation: 0.25s menu-open ease-in-out;
  position: absolute;
  bottom: 0px;
  z-index: 9999999;
  max-height: 100vh;
}
.mobile-menu-area .menu-close-btn {
  width: fit-content;
  height: fit-content;
  margin-bottom: 24px;
  background-color: transparent;
  color: orange;
  border: none;
  font-weight: bold;
  font-size: 18px;
}
.mobile-menu-area ul {
  margin-top: 24px !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0px;
  margin: 0px;
  height: 100%;
  list-style-type: none;
}
.mobile-menu-area ul li {
  padding: 12px 0px;
  margin-top: 12px;
  text-decoration: none;
  text-transform: none;
  font-weight: 600;
  border-bottom: 1px solid #f5f5f5;
  color: rgb(171, 230, 255);
}
.mobile-menu-area ul li a {
  text-decoration: none;
  text-transform: none;
  color: rgb(171, 230, 255);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.51px;
}
.mobile-menu-area ul li a:hover {
  color: #1DA1F2;
}
.mobile-menu-area .menu-area-footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@keyframes menu-open {
  from {
    bottom: -500px;
  }
  to {
    bottom: 0px;
  }
}
.btn-icon-primary {
  color: white;
  z-index: 888;
  text-align: center;
  min-width: 5rem;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding: 0px 40px;
  align-self: center;
  height: 2.7rem;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.26px;
  border: none;
  text-decoration: none;
  transition: all 0.4s ease 0s;
  background-color: #1DA1F2;
}
@media screen and (max-width: 600px) {
  .btn-icon-primary {
    max-width: 200%;
    font-size: 9px;
  }
}
@media screen and (max-width: 350px) {
  .btn-icon-primary {
    height: 54px;
    font-size: 9px;
  }
}
.btn-icon-primary svg {
  width: 18px;
  margin-left: 8px;
}

.hero {
  min-height: 85vh;
}
.hero .hero-title-wrapper h1 {
  font-size: 62px;
  line-height: 72px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 0px;
  width: 90%;
  color: #00004D;
}
.hero .hero-title-wrapper h1 span {
  color: #3F8AF8;
}
.hero .hero-title-wrapper p {
  margin-top: 40px;
  margin-bottom: 40px;
  color: rgb(7, 8, 23);
  width: 70%;
  font-size: 18px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 26px;
}
@media screen and (max-width: 600px) {
  .hero .hero-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .hero .hero-title-wrapper h1 {
    font-size: 38px !important;
    line-height: 48px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 54px;
    color: #00004D !important;
    text-align: center;
  }
  .hero .hero-title-wrapper p {
    margin: 54px 0px 12px 0px;
    text-align: center;
  }
}
.hero .hero-img-wrapper {
  background-image: url("../images/background-img.svg");
  background-size: 100% no-repeat;
}
.hero .hero-img-wrapper img {
  width: 100%;
}

.hero2 {
  height: 85vh;
}
@media screen and (max-width: 400px) {
  .hero2 h1 {
    font-size: 32px !important;
    line-height: 48px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 54px;
    color: rgb(7, 8, 23);
  }
}
.hero2 h1 {
  font-size: 44px;
  line-height: 62px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 54px;
  color: rgb(7, 8, 23);
}
.hero2 h5 {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgb(7, 8, 23);
  width: 100%;
  font-size: 18px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}
.hero2 text {
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgb(7, 8, 23);
  width: 70%;
  font-size: 18px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 26px;
}
.hero2 .title-wrapper {
  min-height: 85%;
  padding-bottom: 64px;
}
.hero2 .partners img {
  width: 10%;
  padding-bottom: 8px;
}
@media screen and (max-width: 600px) {
  .hero2 .partners img {
    width: 20% !important;
    padding-bottom: 8px;
  }
}

.about-wrapper {
  background-color: #14171A;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-wrapper h1 {
  color: #FBFBFB;
}
.about-wrapper p {
  font-size: 16px;
  color: #c4c4c4;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
  margin-top: 22px;
  margin-bottom: 56px;
  max-width: 45ch !important;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: justify;
}
.about-wrapper .hsmonica-partner {
  margin-top: 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}
.about-wrapper .hsmonica-partner h5 {
  font-weight: 600;
  margin-bottom: 24px;
  color: #4D6175;
}
.about-wrapper .hsmonica-partner img {
  max-width: 35%;
}

.about-misc-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  width: 45%;
}

.benefits-wrapper {
  border-radius: 8px;
  border-radius: 8px;
  padding: 24px;
  min-height: 250px;
}
.benefits-wrapper .benefits-icon-wrapper {
  padding: 12px;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.benefits-wrapper p {
  font-weight: 600;
  margin-top: 16px;
}
.benefits-wrapper a {
  color: #1DA1F2;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}
.benefits-wrapper text {
  color: #7C7A80;
}

.team-container {
  padding: 84px 0px;
  min-height: 75vh;
  display: flex;
  align-items: center;
  background: white;
  overflow: scroll;
}
.team-container .team-wrapper {
  box-shadow: rgba(69, 69, 80, 0.2) 8px 8px 32px;
  background-color: rgb(255, 255, 255);
  padding: 2em;
  z-index: 2;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  align-items: stretch;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 500;
  transition: all 0.3s linear 0s;
  border-top: 5px solid rgb(255, 255, 255);
  overflow: scroll;
  max-width: 375px;
  min-width: 100%;
}
.team-container .team-wrapper .team-avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-container .team-wrapper .team-avatar-wrapper img {
  max-width: 120px;
}
.team-container .team-wrapper h1 {
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0px;
  color: rgb(0, 0, 0);
  margin-bottom: 24px;
  margin-top: 12px;
}
.team-container .team-wrapper text {
  font-family: "Lato", "sans-serif";
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #1B1C29;
}
.team-container .team-wrapper p {
  text-align: center;
  font-family: "Lato", "sans-serif";
  font-weight: 500;
  font-weight: 300;
  color: #2f3243;
}
.team-container .team-wrapper p:before {
  display: block;
  margin-bottom: 12px;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.team-container .advisor {
  color: orange;
  font-weight: 300;
  display: none;
}
@media screen and (max-width: 500px) {
  .team-container {
    margin-top: 64px;
  }
  .team-container .advisor {
    display: block;
  }
}
@media screen and (max-width: 350px) {
  .team-container {
    margin-top: 120px;
  }
}

.scroll-1 {
  transform: translateX(-500px) !important;
}

#team-scroll {
  overflow: scroll;
}
#team-scroll -webkit-scrollbar,
#team-scroll -webkit-scrollbar-track {
  display: block !important;
  background-color: red;
}

.princing-container {
  padding: 64px 0px;
  background-color: #EFF4F8;
  flex-wrap: wrap;
}
.princing-container .title {
  text-align: center;
  font-size: 36px;
  line-height: 50px;
  color: rgb(42, 42, 59);
  font-weight: 600;
  position: relative;
  margin-bottom: 64px;
}
.princing-container .title:before {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 64px;
  background-color: #14171A;
}
.princing-container .promotional-text-wrapper :nth-child(1) {
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  max-width: 426px;
  color: rgb(43, 43, 43);
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.princing-container .promotional-text-wrapper :nth-child(1) strong {
  color: #2D7EFC;
}
.princing-container .promotional-text-wrapper :nth-child(2) {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 50px;
  text-align: center;
  width: 100%;
}
.princing-container svg {
  max-width: 500px;
}

.cards-wrapper {
  position: relative;
}

.cards {
  display: flex;
  position: absolute;
  left: 60px;
  top: -15px;
  margin-left: 60px;
  top: -15px;
  align-items: center;
}
@media screen and (max-width: 1300px) {
  .cards {
    position: relative !important;
  }
}
.cards .card-primary {
  box-shadow: rgba(69, 69, 80, 0.2) 8px 8px 32px;
  background-color: white;
  width: 250px;
  border-radius: 8px;
  z-index: 999;
}
.cards .card-secondary {
  background-color: white;
  width: 250px;
  height: fit-content;
  border-radius: 8px;
}
.cards h1 {
  display: block;
  width: 100%;
  padding: 15px 0px;
  border-bottom: 1px solid rgb(242, 242, 243);
  color: rgb(139, 139, 146);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  letter-spacing: 0.24em;
  font-weight: 500;
}
.cards h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #14171A;
  text-align: center;
}
.cards p {
  margin-bottom: 12px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgb(69, 69, 80);
}
.cards .item-container {
  padding: 24px;
}
.cards .item-container .item-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.cards .item-container .item-wrapper .item-marker {
  width: 4px;
  height: 4px;
  border: 1px solid #1DA1F2;
  border-radius: 2px;
}
.cards .item-container .item-wrapper p {
  margin: 0px;
  padding: 0px;
  margin-left: 8px;
  font-weight: 500;
}

.card-mobile .card-primary {
  box-shadow: rgba(69, 69, 80, 0.2) 8px 8px 32px;
  background-color: white;
  width: 250px;
  border-radius: 8px;
  z-index: 999;
}
.card-mobile .card-secondary {
  background-color: white;
  width: 250px;
  height: fit-content;
  border-radius: 8px;
}
.card-mobile h1 {
  display: block;
  width: 100%;
  padding: 15px 0px;
  border-bottom: 1px solid rgb(242, 242, 243);
  color: rgb(139, 139, 146);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  letter-spacing: 0.24em;
  font-weight: 500;
}
.card-mobile h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #14171A;
  text-align: center;
}
.card-mobile p {
  margin-bottom: 12px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgb(69, 69, 80);
}
.card-mobile .item-container {
  padding: 24px;
}
.card-mobile .item-container .item-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.card-mobile .item-container .item-wrapper .item-marker {
  width: 4px;
  height: 4px;
  border: 1px solid #1DA1F2;
  border-radius: 2px;
}
.card-mobile .item-container .item-wrapper p {
  margin: 0px;
  padding: 0px;
  margin-left: 8px;
  font-weight: 500;
}

.faq-container {
  padding: 64px 0px;
  background-color: #0D2C77;
}
.faq-container .title {
  text-align: center;
  font-family: "Poppins", "sans-serif";
  font-size: 36px;
  line-height: 50px;
  color: white;
  font-weight: 600;
  position: relative;
  margin-bottom: 64px;
}
.faq-container .title:before {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 64px;
  background-color: #1DA1F2;
}
.faq-container .question-wrapper {
  width: 100%;
}
.faq-container .question-wrapper :nth-child(1) {
  display: flex;
  justify-content: space-between;
}
.faq-container .question-wrapper :nth-child(1) p {
  text-align: center;
  font-family: "Poppins", "sans-serif";
  font-size: 28px;
  line-height: 50px;
  color: #FBFBFB;
  font-weight: 600;
  position: relative;
  margin-right: 8px;
  max-width: 80%;
}
.faq-container .question-wrapper :nth-child(1) button {
  width: 40px !important;
  height: 40px !important;
  background-color: transparent;
  border: 1.2px solid white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.faq-container .question-wrapper :nth-child(2) {
  font-family: "Poppins", "sans-serif";
  color: #f5f5f5;
}
.faq-container .divider {
  width: 100%;
  height: 1px;
  background-color: #1DA1F2;
}

.footer-container {
  background-color: #14171A;
  padding: 40px 0px 0px 0px;
  font-family: "Poppins", "open-sans";
}
.footer-container a {
  text-decoration: none;
  cursor: pointer;
}
.footer-container h5 {
  color: #919DAB;
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.footer-container p {
  color: white;
}
.footer-container p span {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: bold;
}
.footer-container .footer-address {
  background-color: #272D33;
  padding: 12px 0px 12px 0px;
}
.footer-container .footer-address p {
  margin: 0px;
  padding: 0px;
}

.title-internal {
  font-size: 32px !important;
  margin-bottom: 42px;
}

@font-face {
  font-family: "card-font";
  src: url("../fonts/nokio-slab-regular.otf");
}
.whytobepartner {
  padding: 64px 0px;
  font-family: "Comfortaa", "cursive";
  background-color: white;
}
.whytobepartner h1 {
  font-size: 32px;
  line-height: 42px;
  color: #14171A;
  font-weight: 600;
  position: relative;
  margin-bottom: 24px;
}
.whytobepartner h5 {
  color: #212121;
}
.whytobepartner .icon-wrapper {
  background-color: transparent;
  height: 48px !important;
  width: 48px !important;
  border-radius: 48px;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: center;
}
.whytobepartner p {
  margin: 0px;
  padding: 0px;
  margin-left: 12px;
  color: #5A5A71;
  width: fit-content;
  font-weight: medium;
}
.whytobepartner img {
  max-width: 50%;
  margin-top: 60px;
}
.whytobepartner h4 {
  color: #4F6678;
  text-transform: uppercase;
  font-size: 14px;
}

.partners-container {
  background-color: #14171A;
  padding: 64px 32px;
}
.partners-container h4 {
  color: #c4c4c4;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
}
.partners-container h1 {
  color: #FBFBFB;
}
@media screen and (min-width: 667px) {
  .partners-container .btn-compacted {
    display: none !important;
  }
}

.title-internal {
  font-size: 32px !important;
  margin-bottom: 42px;
}

@font-face {
  font-family: "card-font";
  src: url("../fonts/nokio-slab-regular.otf");
}
.lead-container {
  font-family: "Poppins", "cursive";
  min-height: 200vh;
  min-width: 200vw;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12000;
  overflow: auto;
}
.lead-container .col-left {
  height: 100vh;
  min-width: 100vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
}
.lead-container .col-right {
  box-sizing: border-box;
  position: fixed;
  background-color: #FBFBFB;
  animation: 0.25s col-right ease-in-out;
  width: 30vw;
  right: 0;
  top: 0;
  height: 100%;
  overflow: scroll;
  z-index: 120000;
  padding: 64px 24px;
}
.lead-container .col-right .close-wrapper {
  display: flex;
  justify-content: flex-end;
}
.lead-container .col-right .close-wrapper button {
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bold;
}
.lead-container .col-right .title-wrapper {
  display: flex;
  align-items: flex-start;
}
.lead-container .col-right .title-wrapper h1 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
  width: 90%;
  color: #14171A;
}
.lead-container .col-right p {
  font-weight: 500;
  font-size: 16px;
  color: #2D3240;
  padding: 0px;
  margin: 0px;
}
.lead-container .col-right .input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lead-container .col-right .input-wrapper label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.lead-container .col-right .input-wrapper label input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  border: 2px solid #E1E8ED;
  padding: 0px 8px;
  color: #14171A;
}
.lead-container .col-right .input-wrapper label p {
  margin: 0px;
  padding: 0px;
  color: #0D2C77;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
.lead-container .col-right .input-wrapper .lead-submit {
  margin-top: 24px;
  width: 100%;
  height: 44px !important;
  border-radius: 100px;
  background-color: #1DA1F2;
  color: white;
  border: none;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .lead-container .col-right {
    width: 90vw;
  }
}
@media screen and (max-height: 800px) {
  .lead-container .col-right {
    padding-top: 120px;
  }
}
@media screen and (max-height: 721px) {
  .lead-container .col-right {
    padding-top: 200px;
  }
}
@media screen and (max-height: 645px) {
  .lead-container .col-right {
    padding-top: 280px;
  }
}
@media screen and (max-height: 554px) {
  .lead-container .col-right {
    padding-top: 60vh;
  }
  .lead-container .col-right .lead-submit {
    font-size: 12px;
  }
}
@media screen and (max-height: 554px) {
  .lead-container .col-right {
    padding-top: 80vh;
  }
}

@keyframes col-right {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0px);
  }
}
.title-internal {
  font-size: 32px !important;
  margin-bottom: 42px;
}

@font-face {
  font-family: "card-font";
  src: url("../fonts/nokio-slab-regular.otf");
}
.work-with-us-container {
  font-family: "Poppins", "cursive";
  min-height: 200vh;
  min-width: 200vw;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12000;
  overflow: auto;
}
.work-with-us-container .col-left {
  height: 100vh;
  min-width: 100vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
}
.work-with-us-container .col-right {
  box-sizing: border-box;
  position: fixed;
  background-color: #FBFBFB;
  animation: 0.25s col-right ease-in-out;
  width: 30vw;
  right: 0;
  top: 0;
  height: 100%;
  overflow: scroll;
  z-index: 120000;
  padding: 64px 24px;
}
.work-with-us-container .col-right .close-wrapper {
  display: flex;
  justify-content: flex-end;
}
.work-with-us-container .col-right .close-wrapper button {
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bold;
}
.work-with-us-container .col-right .title-wrapper {
  display: flex;
  align-items: flex-start;
}
.work-with-us-container .col-right .title-wrapper h1 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
  width: 90%;
  color: #14171A;
}
.work-with-us-container .col-right p {
  font-weight: 500;
  font-size: 16px;
  color: #2D3240;
  padding: 0px;
  margin: 0px;
}
.work-with-us-container .col-right .input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-container .col-right .input-wrapper label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.work-with-us-container .col-right .input-wrapper label input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  border: 2px solid #E1E8ED;
  padding: 0px 8px;
  color: #14171A;
}
.work-with-us-container .col-right .input-wrapper label p {
  margin: 0px;
  padding: 0px;
  color: #0D2C77;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
.work-with-us-container .col-right .input-wrapper .lead-submit {
  margin-top: 24px;
  width: 100%;
  height: 44px !important;
  border-radius: 100px;
  background-color: #1DA1F2;
  color: white;
  border: none;
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .work-with-us-container .col-right {
    width: 90vw;
  }
}
@media screen and (max-height: 800px) {
  .work-with-us-container .col-right {
    padding-top: 120px;
  }
}
@media screen and (max-height: 721px) {
  .work-with-us-container .col-right {
    padding-top: 200px;
  }
}
@media screen and (max-height: 645px) {
  .work-with-us-container .col-right {
    padding-top: 280px;
  }
}
@media screen and (max-height: 554px) {
  .work-with-us-container .col-right {
    padding-top: 60vh;
  }
  .work-with-us-container .col-right .lead-submit {
    font-size: 12px;
  }
}
@media screen and (max-height: 554px) {
  .work-with-us-container .col-right {
    padding-top: 80vh;
  }
}

@keyframes col-right {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0px);
  }
}
.find-benefits {
  height: 100vh;
}
.find-benefits .logo-mobile {
  background-color: #6E5AED;
}
.find-benefits .logo-mobile svg {
  max-width: 50%;
}
.find-benefits .left-content {
  background-color: #1DA1F2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.find-benefits .left-content .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.find-benefits .left-content .phrase-container {
  max-width: 60%;
  height: 35vh;
  align-self: center;
  display: flex;
  margin-top: 15vh;
}
.find-benefits .left-content .phrase-container h1 {
  font-family: "Poppins", "open-sans";
  font-size: 57px;
  line-height: 67px;
  color: white;
  text-align: start;
}
@media screen and (max-width: 1600px) {
  .find-benefits .left-content .phrase-container h1 {
    font-family: "Poppins", "open-sans";
    font-size: 48px;
    line-height: 58px;
    color: white;
  }
}
.find-benefits .left-content .background-img-container {
  padding: 0px;
  margin: 0px;
  max-height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.find-benefits .left-content .background-img-container img {
  overflow: hidden !important;
  max-width: 100%;
  max-height: 100%;
  bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  left: auto;
  right: auto;
}
@media screen and (max-width: 1300px) {
  .find-benefits .left-content {
    position: relative !important;
  }
}
.find-benefits .right-content {
  padding: 32px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}
.find-benefits .right-content .choose-type {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.find-benefits .right-content .choose-type button {
  padding: 8px 8px;
  min-width: 80%;
  border-radius: 100px;
  margin: 4px 0px;
  background-color: transparent;
  border: 1px solid #212121;
}
.find-benefits .right-content .header {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 24px;
  padding: 0px 32px;
  display: flex;
  align-items: center;
}
.find-benefits .right-content .header .name {
  color: #212121;
  font-weight: bold;
}
.find-benefits .right-content .header p {
  font-size: 14px;
}
.find-benefits .right-content .header span {
  color: #4264fb;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.find-benefits .right-content .search-professional-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.find-benefits .right-content .search-professional-wrapper button {
  align-items: center;
  justify-content: center;
  background-color: transparent;
  display: flex;
  border-radius: 100px;
  padding: 4px 16px;
  border: 1px solid #212121;
}
.find-benefits .right-content .search-professional-wrapper button span {
  margin-right: 8px;
  font-size: 20px;
}
.find-benefits .right-content .filter-box-wrapper {
  padding: 0px 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 64px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Lato", "open-sans";
}
.find-benefits .right-content .filter-box-wrapper .filter-box {
  margin-bottom: 4px;
  cursor: pointer;
  margin-right: 8px;
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #14171A;
  border-radius: 100px;
  justify-content: center;
  color: #14171A;
}
.find-benefits .right-content .filter-box-wrapper .filter-box p {
  padding: 0px;
  margin: 0px;
}
.find-benefits .right-content .filter-box-wrapper .filter-box-active {
  margin-bottom: 4px;
  margin-right: 8px;
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  background-color: #14171A;
  justify-content: center;
  border: 1px solid #14171A;
  border-radius: 100px;
  color: white;
}
.find-benefits .right-content .filter-box-wrapper .filter-box-active p {
  padding: 0px;
  margin: 0px;
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper {
  padding: 0px 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Lato", "open-sans";
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper span {
  margin-right: 8px;
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper p {
  padding: 0px;
  margin: 0px;
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper .filter-box {
  margin-bottom: 4px;
  cursor: pointer;
  margin-right: 8px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #14171A;
  border-radius: 100px;
  justify-content: center;
  color: #14171A;
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper .filter-box-active {
  margin-bottom: 4px;
  margin-right: 8px;
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  background-color: #14171A;
  justify-content: center;
  border: 1px solid #14171A;
  border-radius: 100px;
  color: white;
}
.find-benefits .right-content .all-professionals-filter .filter-box-wrapper .filter-box-active p {
  padding: 0px;
  margin: 0px;
}
.find-benefits .right-content .partners-wrapper {
  padding: 0px 32px;
}
.find-benefits .right-content .partners-wrapper .professionals-details-wrapper {
  display: flex;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
}
.find-benefits .right-content .partners-wrapper .professionals-details-wrapper img {
  max-width: 48px;
  max-height: 48px;
  margin-right: 24px;
}
.find-benefits .right-content .partners-wrapper .professionals-details-wrapper .professional-detail-body .professional-name {
  font-weight: 600;
}
.find-benefits .right-content .partners-wrapper .professionals-details-wrapper .professional-detail-body .professional-council {
  font-size: 12px;
}
.find-benefits .right-content .partners-wrapper .professionals-details-wrapper .professional-detail-body p {
  margin: 0;
  padding: 0;
}
.find-benefits .right-content .partners-wrapper .partner-card {
  max-width: 400px;
  max-height: 300px;
  border-radius: 8px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-avatar {
  background-color: #EFF4F8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  box-shadow: 0px 0px 1px 1px #e0e0e0;
  border-radius: 4px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px #e0e0e0;
  background-color: #f9f9f9;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body {
  padding: 16px 16px;
  color: #212121;
  font-weight: 400;
  font-size: 16px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body p {
  font-weight: 600;
  font-size: 18px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body .partner-address {
  display: flex;
  align-items: center;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body .partner-address svg {
  margin-right: 8px;
  max-width: 24px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body .partner-contact {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-body .partner-contact svg {
  margin-left: 4px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-footer {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
}
.find-benefits .right-content .partners-wrapper .partner-card .partner-card-footer button {
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  color: #4264fb;
  border: none;
  outline: none;
}
.find-benefits .right-content .filter-box-mobile-wrapper {
  margin-bottom: 32px;
  padding: 0px 32px;
  display: none;
}
.find-benefits .right-content .filter-box-mobile-wrapper .btn-wrapper {
  display: flex;
  align-items: center;
}
.find-benefits .right-content .filter-box-mobile-wrapper .btn-wrapper p {
  margin: 0;
  padding: 0;
}
.find-benefits .right-content .filter-box-mobile-wrapper .btn-wrapper button {
  margin-left: 8px;
  border-radius: 100px;
  border: 1px solid #6E5AED;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6E5AED;
  padding: 2px 16px;
  box-shadow: 0px 0px 2px 2px #f9f9f9;
}
@media screen and (max-width: 1920px) {
  .find-benefits .right-content .filter-box-wrapper {
    display: none;
  }
  .find-benefits .right-content .filter-box-mobile-wrapper {
    display: block;
  }
  .find-benefits .right-content .all-professionals-filter .filter-box-wrapper {
    display: none;
  }
  .find-benefits .right-content .all-professionals-filter .filter-box-mobile-wrapper {
    display: block;
  }
}

.mobile-hidden-categories {
  z-index: 9999;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
}
.mobile-hidden-categories .background {
  width: 100vw;
  height: 100vh;
  border: none;
  background-color: transparent;
}
.mobile-hidden-categories .hidden-menu-category {
  background-color: rgb(0, 15, 64);
  width: 100vw;
  max-height: 100vh;
  overflow: scroll;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: categories-menu 0.4s ease-in-out;
}
.mobile-hidden-categories .hidden-menu-category .item-wrapper {
  padding: 16px 16px;
  border-bottom: 1px solid #c4c4c4;
}
.mobile-hidden-categories .hidden-menu-category .item-wrapper button {
  background-color: transparent;
  border: none;
  outline: none;
  color: rgb(171, 230, 255);
}

@keyframes categories-menu {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}
.benefit-btn-select {
  background-color: #1DA1F2;
  border-radius: 100px;
  padding: 9px 16px;
  width: 100%;
  max-width: 300px;
  border: none;
  color: #f5f5f5;
  font-weight: 600;
}

.benefit-back-btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.benefit-back-btn span {
  margin-right: 8px;
}

.profile {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 64px;
}
.profile .logo-wrapper {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100%;
}
.profile h4 {
  font-size: 18px;
}
.profile h4 span {
  color: #4264fb;
}
.profile .card-wrapper {
  background-image: url("../images/card.svg");
  background-size: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 242px;
  max-width: 350px;
  border-radius: 20px;
  flex-direction: column;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile .card-wrapper .card-cpf {
  align-self: flex-start;
  position: absolute;
  bottom: 56px;
  left: 16px;
}
.profile .card-wrapper .card-cpf p {
  color: #46E3B4;
  font-size: 12px;
  font-weight: bold;
}
.profile .card-wrapper .card-name {
  width: 100%;
  position: absolute;
  bottom: 24px;
  left: 16px;
}
.profile .card-wrapper .card-name p {
  color: #f5f5f5;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
}
.profile .card-wrapper .card-qr-code {
  cursor: pointer;
  position: absolute;
  right: 12px;
  bottom: 42px;
  z-index: 999;
  border-radius: 4px;
  border: 2px solid #212121;
}
.profile .card-wrapper .card-qr-code img {
  height: 100px;
  width: 100px;
  transition: all 0.2s ease-in-out;
}
.profile .card-wrapper .card-qr-code:hover {
  cursor: pointer;
  position: absolute;
  right: -24px;
  bottom: -32px;
  z-index: 999;
  border-radius: 2px;
}
.profile .card-wrapper .card-qr-code:hover img {
  height: 350px;
  width: 350px;
}
.profile .button-secondary {
  background-color: transparent !important;
  color: #212121 !important;
  text-decoration: underline;
}
.profile .partner-area-content {
  width: 100%;
}
.profile .partner-area-content .input-wrapper {
  max-width: 400px;
  flex-direction: row;
}
.profile .logout-btn {
  background-color: transparent;
  text-decoration: none !important;
  border-radius: 4px;
  color: rgb(236, 89, 89) !important;
  padding: 4px 8px;
  border: 1px solid rgb(236, 89, 89) !important;
}
.profile .user-data-card {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile .user-data-card .br-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.profile .user-data-card .br-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.profile .user-data-card .item-wrapper {
  background-color: white;
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
}
.profile .user-data-card .item-wrapper p {
  margin: 0;
}
.profile .user-data-card .item-wrapper .title {
  padding: 8px 16px;
  color: #212121;
}
.profile .user-data-card .item-wrapper .description {
  padding: 8px 16px;
  color: #7d7d7d;
  padding-right: 84px;
}
.profile .user-data-card .item-wrapper .edit {
  position: absolute;
  right: 16px;
  display: flex;
  top: 0;
  bottom: 0;
  margin-top: 0px;
  margin-bottom: 0px;
}
.profile .user-data-card .item-wrapper .edit button {
  border: none;
  color: #4264fb;
  background-color: transparent;
}

.card-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card-user .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.card-user .btn-wrapper .btn-active {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #1DA1F2;
  color: #1DA1F2;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}
.card-user .btn-wrapper .btn-inactive {
  background-color: transparent;
  border: none;
  color: "black";
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}
.card-user .card-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
}
.card-user .card-background .card-letters-wrapper {
  position: absolute;
  display: flex;
  width: 88%;
  height: 50%;
  bottom: 36px;
  justify-content: space-evenly;
}
.card-user .card-background .card-letters-wrapper p {
  margin: 0px;
  padding: 0px;
  color: #f1f1f1;
  font-family: "card-font";
  text-transform: uppercase;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .card-user .card-background .card-letters-wrapper p {
    font-size: 10px;
  }
}
.card-user .card-background .card-letters-wrapper .card-letters-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-user .card-background .card-letters-wrapper .card-letters-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-user .card-background .card-letters-wrapper .card-letters-title {
  font-size: 9px;
  letter-spacing: 1.2px;
  margin-bottom: 4px;
}
@media screen and (max-width: 800px) {
  .card-user .card-background .card-letters-wrapper .card-letters-title {
    font-size: 10px;
  }
}
.card-user .card-background img {
  width: 100%;
}
.card-user .download-card-btn {
  background-color: #1DA1F2;
  border: none;
  color: white;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  margin-top: 12px;
}

.title-internal {
  font-size: 32px !important;
  margin-bottom: 42px;
}

@font-face {
  font-family: "card-font";
  src: url("../fonts/nokio-slab-regular.otf");
}
.headerV2 {
  font-family: "Poppins", "cursive";
  position: fixed;
  width: 100vw;
  font-weight: 400px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  height: 8vh;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: white;
}
.headerV2 .logo-wrapper {
  max-width: fit-content;
  display: flex;
  align-items: center;
  height: 100%;
}
.headerV2 .menu-wrapper {
  display: flex;
  align-items: center;
}
.headerV2 .menu-wrapper ul {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.headerV2 .menu-wrapper ul li {
  list-style: none;
  margin-right: 24px;
  color: #14171A;
}
.headerV2 .menu-wrapper ul li a {
  text-decoration: none;
  color: #14171A;
}
.headerV2 .menu-wrapper ul li svg {
  margin-left: 8px;
}
.headerV2 .menu-wrapper ul li .soon {
  position: absolute;
  top: 24px;
  right: 0px;
  margin: auto;
  background-color: #0D2C77;
  text-align: center;
  border-radius: 4px;
  font-size: 8px;
  text-transform: uppercase;
  width: fit-content;
  padding: 2px 8px;
  font-weight: bold;
  z-index: 2;
  color: white;
}
.headerV2 .menu-wrapper ul .download-app {
  position: relative;
  transition: all 0.25s ease-in-out;
}
.headerV2 .menu-wrapper ul .download-app .hidden-menu {
  padding: 24px;
  background-color: #0D2C77;
  position: absolute;
  visibility: hidden;
  left: -150px;
  top: 20px;
  border-radius: 0px 0px 10px 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FBFBFB;
  opacity: 0;
  z-index: 20;
}
.headerV2 .menu-wrapper ul .download-app .hidden-menu svg {
  margin-bottom: 24px;
}
.headerV2 .menu-wrapper ul .download-app:hover .hidden-menu {
  visibility: visible;
  transition: all 0.45s ease-in-out;
  opacity: 1;
}
.headerV2 .cta-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.headerV2 .cta-btn-wrapper p {
  margin: 0px;
  padding: 0px;
}
.headerV2 .cta-btn-wrapper button {
  margin-left: 24px;
  background-color: #1DA1F2;
  border: none;
  height: 44px;
  padding: 0px 24px;
  border-radius: 44px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerV2 .cta-btn-wrapper button svg {
  margin-left: 8px;
}

.herov3 .left-content {
  flex-direction: column;
  padding-top: 8%;
}
.herov3 .left-content h1 {
  font-weight: 48px;
  font-family: "Poppins", "cursive";
  max-width: 480px;
  color: #14171A;
  font-weight: bold;
}
.herov3 .left-content h4 {
  font-size: 18px;
  color: #585858;
  line-height: 150%;
  margin: 24px 0px 42px 0px;
  font-family: "Poppins", "cursive";
  max-width: 45ch;
}
.herov3 .left-content button {
  background-color: #1DA1F2;
  border: none;
  height: 44px;
  padding: 0px 24px;
  border-radius: 44px;
  color: #fbfbfb;
  font-weight: bold;
  max-width: fit-content;
  font-family: "Poppins", "cursive";
  display: flex;
  align-items: center;
  justify-content: center;
}
.herov3 .left-content button svg {
  margin-left: 8px;
}
.herov3 .left-content :nth-child(1) {
  opacity: 1;
  animation: fade-and-move 0.85s ease-in-out;
}
.herov3 .left-content :nth-child(2) {
  opacity: 1;
  animation: fade-and-move 0.85s ease-in-out;
}
.herov3 .left-content :nth-child(3) {
  opacity: 1;
  animation: fade-and-move 0.85s ease-in-out;
}
@media screen and (max-width: 991px) {
  .herov3 .left-content {
    height: fit-content !important;
    margin-top: 64px;
  }
}
.herov3 .right-content {
  padding-top: 8%;
  display: flex;
}
.herov3 .right-content img {
  max-height: 75%;
  animation: fade 0.85s ease-in-out;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 991px) {
  .herov3 .right-content {
    margin-top: 64px;
    align-items: center;
    justify-content: center;
    display: none;
  }
  .herov3 .right-content img {
    display: none;
    max-width: 100%;
  }
}
.herov3 .store-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: 100%;
}
.herov3 .store-btns button {
  margin: 0px 4px;
  background-color: transparent;
  border: none;
  outline: none;
}
@media screen and (max-width: 990px) {
  .herov3 {
    margin-bottom: 32px;
  }
  .herov3 .store-btns {
    display: none;
    max-width: 330px;
  }
}

@media screen and (min-width: 1200px) {
  .herov4 {
    margin-top: 100px;
    min-height: 82vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/5110-banner-desktop.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-clip: border-box;
    background-position-y: center;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .herov4 {
    margin-top: 50px;
    min-height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/5110-banner-desktop.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 900px) {
  .herov4 {
    min-height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/5110-banner-desktop.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 767px) {
  .herov4 {
    min-height: 400px;
    margin-top: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/5110-banner-desktop.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 580px) {
  .herov4 {
    margin-top: 60px;
    min-height: 90vh;
    width: 100vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../images/5110-banner-mobile.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    margin-bottom: 42px;
  }
}

.inner-img {
  display: flex;
  height: fit-content;
  overflow: hidden;
  margin-bottom: 32px;
}
@media screen and (max-width: 880px) {
  .inner-img {
    margin-top: 100px;
    min-height: 82vh;
    margin-bottom: 30px;
  }
}
.inner-img .img-item img {
  width: 100vw;
  max-height: 90vh;
  min-height: 650px;
  object-fit: contain;
}
@media screen and (min-width: 590px) {
  .inner-img .img-item img {
    padding: 60px;
  }
}

.carousel .indicator-active {
  background-color: orange;
  width: 48px;
  height: 4px;
  border: none;
  border-radius: 200px;
  margin: 0px 4px;
}
.carousel .indicator-inactive {
  background-color: #c5c5c5;
  width: 48px;
  height: 4px;
  border: none;
  border-radius: 200px;
  margin: 0px 4px;
}

.carousel-right {
  z-index: 999;
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  right: 50px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  border-radius: 200px;
}

.carousel-left {
  z-index: 999;
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 200px;
  left: 50px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
}

.carousel-left:hover {
  background-color: orange;
}

.carousel-right:hover {
  background-color: orange;
}

.title-before::before {
  content: "";
  display: block;
  width: 32px;
  height: 6px;
  background: #7F7FD5; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 100px;
  margin-bottom: 24px;
}

.title-before-center::before {
  content: "";
  display: block;
  width: 32px;
  height: 6px;
  margin: auto;
  background: #7F7FD5; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 100px;
  margin-bottom: 24px;
}

.top-advisor {
  background-color: #4264fb;
  padding: 12px 0px;
  text-align: center;
}
.top-advisor a {
  color: white;
}

#map {
  height: 180px;
  width: 100%;
}

.partner-user-modal .item-wrapper .title {
  color: #1DA1F2;
  font-weight: bold;
}

.situation-text-ok {
  background-color: #42ec9a;
  color: #20252a;
  padding: 24px;
  border-radius: 4px;
}

.situation-text-error {
  background-color: rgba(236, 66, 109, 0.2274509804);
  color: #20252a;
  padding: 12px;
  border-radius: 4px;
}

.scanner {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vW;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.scanner button {
  margin-top: 24px;
  max-width: 200px;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  background-color: #1DA1F2;
  color: white;
  border: none;
}
.scanner .scanner-bar {
  width: 100%;
  height: 100%;
  max-height: 250px;
  max-width: 250px;
  border: 1px solid orange;
}

.input-wrapper-2 {
  width: 100%;
  border: 0.0714285714rem solid #dcdcdc;
  padding: 12px;
  display: flex;
  position: relative;
  transition: all ease-in-out 0.25s;
}
.input-wrapper-2 .text-input-error {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 10px;
}
.input-wrapper-2 .text-input-error .material-icons-round {
  font-size: 14px;
  margin-right: 4px;
}
.input-wrapper-2 .spinner-container {
  visibility: hidden;
}
.input-wrapper-2 label {
  color: #212121;
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  background-color: transparent;
  width: 100%;
  line-height: 1.8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.input-wrapper-2 input {
  display: block;
  font-size: 1rem;
  background-color: transparent;
  color: #616161;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
}
.input-wrapper-2 input::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #696969 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-loading {
  background-color: rgb(0, 15, 64) !important;
  position: relative;
}
.input-loading label {
  color: orange;
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  line-height: 1.8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.input-loading .spinner-container {
  visibility: visible;
  position: absolute;
  right: 16px;
  top: 28px;
}
.input-loading .spinner-container .spinner-grow {
  font-size: 4px;
  width: 12px;
  height: 12px;
}

.input-wrapper-2:focus-within {
  background-color: rgb(0, 15, 64) !important;
}
.input-wrapper-2:focus-within label {
  color: orange;
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  line-height: 1.8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.input-wrapper-2:focus-within input {
  display: block;
  font-size: 1rem;
  color: #f5f5f5;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
}

.option-wrapper-2 {
  width: 100%;
  border: 0.0714285714rem solid #dcdcdc;
  padding: 12px;
  position: relative;
  transition: all ease-in-out 0.25s;
  min-height: 76.67px;
}
.option-wrapper-2 .text-input-error {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 10px;
}
.option-wrapper-2 .text-input-error .material-icons-round {
  font-size: 14px;
  margin-right: 4px;
}
.option-wrapper-2 label {
  color: #212121;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  background-color: transparent;
  width: 100%;
  line-height: 1.8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.option-wrapper-2 input {
  display: block;
  font-size: 1rem;
  background-color: transparent;
  color: #616161;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
}
.option-wrapper-2 input::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #696969 !important;
}
.option-wrapper-2 input::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #696969 !important;
}
.option-wrapper-2 select {
  display: block;
  font-size: 1rem;
  background-color: transparent;
  color: #616161;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
  -webkit-appearance: none;
}
.option-wrapper-2 select::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #696969 !important;
}
.option-wrapper-2 select::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #696969 !important;
}
.option-wrapper-2 span {
  transition: all ease-in-out 0.25s;
  position: absolute;
  right: 8px;
  top: 28px;
  color: orange;
}
.option-wrapper-2 .hidden-content {
  visibility: hidden;
  max-height: 100vh;
  z-index: 999;
  position: absolute;
  bottom: inherit;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  overflow: scroll;
}
.option-wrapper-2 .hidden-content .hidden-option {
  background-color: rgb(0, 15, 64) !important;
  color: white;
  border: none;
  display: flex;
  visibility: visible;
  padding: 8px 24px;
  z-index: 999;
}
.option-wrapper-2 .hidden-content::-webkit-scrollbar {
  width: 6px;
}
.option-wrapper-2 .hidden-content::-webkit-scrollbar-thumb {
  background-color: #1DA1F2;
  border-radius: 2px;
  width: 2px;
}
@media screen and (max-width: 768px) {
  .option-wrapper-2 .hidden-content::-webkit-scrollbar {
    display: none;
  }
}

.option-wrapper-2:focus-within {
  background-color: rgb(0, 15, 64) !important;
}
.option-wrapper-2:focus-within label {
  color: orange;
  display: flex;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  line-height: 1.8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.option-wrapper-2:focus-within input {
  display: block;
  font-size: 1rem;
  color: #f5f5f5;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
}
.option-wrapper-2:focus-within select {
  display: block;
  font-size: 1rem;
  color: #f5f5f5;
  width: 100%;
  border: none;
  outline: none;
  flex: 1;
  background-color: rgb(0, 15, 64) !important;
  color: white;
  padding: 8px;
}
.option-wrapper-2:focus-within span {
  transform: rotateZ(180deg);
}
.option-wrapper-2:focus-within .hidden-content {
  opacity: 1;
  visibility: visible;
}
.option-wrapper-2:focus-within .hidden-content > button:focus {
  height: fit-content;
}

.backward-btn {
  background-color: transparent;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin-left: 8px;
  font-weight: bold;
}

.cta-btn-lead {
  margin-top: 24px;
  background-color: orange;
  border: none;
  width: 100%;
  color: #f5f5f5;
  max-width: 500px;
  height: 64px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
}

.processing-payment {
  color: #515151;
  text-align: center;
  margin-top: 12px;
}

.total-value {
  padding: 8px;
  border: 1px solid #1DA1F2;
}
.total-value p {
  text-align: center;
  margin: 0px;
  color: #212121;
  font-weight: 600;
}

.btn-print {
  background-color: #1DA1F2;
  border: none;
  color: white;
  padding: 9px 16px;
  border-radius: 2px;
}

.carousel-control-next-icon {
  background-color: #d5d5d5 !important;
}

.carousel-control-prev-icon {
  background-color: #d5d5d5 !important;
}

.carousel-indicators button {
  background-color: #696969 !important;
}
.carousel-indicators .active {
  background-color: #1DA1F2 !important;
}

@media screen and (max-width: 1669px) {
  .carousel-indicators button {
    background-color: #d5d5d5 !important;
  }
  .carousel-indicators .active {
    background-color: #d5d5d5 !important;
  }
  .carousel-control-next-icon {
    background-color: #d5d5d5 !important;
  }
  .carousel-control-prev-icon {
    background-color: #d5d5d5 !important;
  }
}
.clean-btn {
  background-color: transparent;
  border: none;
}

.qr-code-pix {
  text-align: center;
  font-size: 12px;
  word-wrap: break-word;
  border: 1px solid #d5d5d5;
  padding: 4px;
  border-radius: 4px;
}

.label-authorized {
  background-color: #6200ee;
  width: fit-content;
  min-width: 74px;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  color: white;
  font-size: 8px;
  text-transform: uppercase;
}

.label-ontime {
  background-color: #0067ee;
  width: fit-content;
  min-width: 74px;
  text-align: center;
  padding: 4px;
  border-radius: 2px;
  color: white;
  font-size: 8px;
  text-transform: uppercase;
}

.label-paid {
  background-color: #00ee77;
  width: fit-content;
  text-align: center;
  min-width: 74px;
  padding: 4px;
  border-radius: 2px;
  color: white;
  font-size: 8px;
  text-transform: uppercase;
}

.label-due {
  background-color: #ee004f;
  width: fit-content;
  min-width: 74px;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  color: white;
  font-size: 8px;
  text-transform: uppercase;
}

.label-due {
  background-color: #ee004f;
  width: fit-content;
  text-align: center;
  min-width: 74px;
  padding: 4px;
  border-radius: 2px;
  color: white;
  font-size: 8px;
  text-transform: uppercase;
}

.download-pdf-link {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #1DA1F2;
  font-size: 10px;
  text-transform: uppercase;
}

.steps-container-invisible {
  min-height: 35vh;
  visibility: hidden;
}

.steps-container {
  padding: 64px 0px;
  min-height: 50vh;
  visibility: visible;
  font-family: "Poppins", "cursive";
  opacity: 1;
  animation: steps-container-anim 0.6s ease-in-out;
}
.steps-container .steps {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.steps-container .steps :nth-child(1), .steps-container .steps :nth-child(2), .steps-container .steps :nth-child(3) {
  max-width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.steps-container .steps :nth-child(1) :nth-child(1), .steps-container .steps :nth-child(2) :nth-child(1), .steps-container .steps :nth-child(3) :nth-child(1) {
  margin-bottom: 24px;
}
.steps-container .steps :nth-child(1) :nth-child(2), .steps-container .steps :nth-child(2) :nth-child(2), .steps-container .steps :nth-child(3) :nth-child(2) {
  font-weight: bold;
  text-align: center;
  color: #14171A;
  width: 100%;
}
.steps-container .steps :nth-child(1) :nth-child(3), .steps-container .steps :nth-child(2) :nth-child(3), .steps-container .steps :nth-child(3) :nth-child(3) {
  text-align: justify;
  color: #5A5A71;
}
@media screen and (max-width: 991px) {
  .steps-container .steps :nth-child(1), .steps-container .steps :nth-child(2), .steps-container .steps :nth-child(3) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

@keyframes steps-container-anim {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.banner-container {
  margin-top: 32px;
  margin-bottom: 60px;
}
.banner-container .banner-img {
  margin-top: 32px;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .banner-container {
    margin-bottom: 32px;
  }
}

.product {
  min-height: 100vh;
  background-color: white;
}
.product a {
  color: #459CF9;
}
.product .product-type-wrapper {
  display: flex;
  align-items: center;
  border-bottom: -1px solid #aaafac;
  max-width: 70%;
}
.product .product-type-wrapper span {
  position: relative;
  padding: 24px 0px 12px 0px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 24px;
}
.product .product-type-wrapper span svg {
  margin-right: 4px;
}
.product .product-type-wrapper span .soon {
  position: absolute;
  top: -4px;
  right: 0px;
  margin: auto;
  background-color: #FFFBD2;
  text-align: center;
  border-radius: 4px;
  font-size: 8px;
  text-transform: uppercase;
  width: fit-content;
  padding: 2px 8px;
  font-weight: bold;
  z-index: 2;
}
.product .product-type-wrapper .checked {
  border-bottom: 2px solid #1DA1F2;
}
@media screen and (max-width: 440px) {
  .product .product-type-wrapper span {
    position: relative;
    padding: 24px 0px 12px 0px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 24px;
    font-size: 12px;
  }
  .product .product-type-wrapper span svg {
    margin-right: 4px;
  }
}
.product .product-description-wrapper {
  padding: 32px 0px;
}
.product .product-description-wrapper h5 {
  color: #14171A;
  font-size: 26px;
  line-height: 42px;
  letter-spacing: 0.56px;
  font-weight: 600;
  max-width: 70%;
  margin-bottom: 32px;
}
.product .product-description-wrapper .product-description-list .product-description {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.product .product-description-wrapper .product-description-list .product-description svg {
  min-width: 24px;
  max-width: 24px;
}
.product .product-description-wrapper .product-description-list .product-description p {
  margin: 0px;
  padding: 0px;
  margin-left: 24px;
  max-width: 45ch;
  color: #5A5A71;
}
.product .product-price-card-container {
  font-family: "Poppins", "cursive" !important;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  height: 100vh;
}
.login .situation-text-error {
  background-color: rgba(236, 66, 109, 0.2274509804);
  color: #20252a;
  padding: 12px;
  border-radius: 4px;
}
.login .left-content {
  background-color: #1DA1F2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login .left-content .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.login .left-content .phrase-container {
  max-width: 60%;
  height: 35vh;
  align-self: center;
  display: flex;
  margin-top: 15vh;
}
.login .left-content .phrase-container h1 {
  font-family: "Poppins", "open-sans";
  font-size: 57px;
  line-height: 67px;
  color: white;
  text-align: start;
}
@media screen and (max-width: 1600px) {
  .login .left-content .phrase-container h1 {
    font-family: "Poppins", "open-sans";
    font-size: 48px;
    line-height: 58px;
    color: white;
  }
}
.login .left-content .background-img-container {
  padding: 0px;
  margin: 0px;
  max-height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.login .left-content .background-img-container img {
  overflow: hidden !important;
  max-width: 100%;
  max-height: 100%;
  bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  left: auto;
  right: auto;
}
@media screen and (max-width: 1300px) {
  .login .left-content {
    position: relative !important;
  }
}
.login .right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login .right-content h5 {
  text-align: center;
}
.login .right-content svg {
  margin-bottom: 64px;
}
.login .right-content .text-accent {
  color: #617889;
  margin-top: 24px;
  max-width: 400px;
  text-align: center;
}
.login .right-content .input-wrapper {
  margin-top: 64px;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
}
.login .right-content .input-wrapper button {
  margin-top: 24px;
  background-color: #1DA1F2;
  color: white;
  border-radius: 8px;
  height: 40px;
  border: none;
}
.login .right-content .input-wrapper label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.login .right-content .input-wrapper label input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  border: 2px solid #E1E8ED;
  padding: 0px 8px;
  color: #14171A;
}
.login .right-content .input-wrapper label p {
  margin: 0px;
  padding: 0px;
  color: #0D2C77;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
.login .right-content .input-wrapper label .forgot-password {
  color: #14171A;
  text-decoration: underline;
}

.partner-area, .profile {
  min-height: 100vh;
}
.partner-area h4, .profile h4 {
  color: #0e2127;
}
.partner-area .logo-wrapper, .profile .logo-wrapper {
  min-height: 8vh;
  display: flex;
  align-items: center;
}
.partner-area .text-accent, .profile .text-accent {
  color: #617889;
  margin-top: 24px;
  max-width: 400px;
}
.partner-area .situation-text-ok, .profile .situation-text-ok {
  background-color: #42ec9a;
  color: #20252a;
  padding: 24px;
  border-radius: 4px;
}
.partner-area .logout-btn, .profile .logout-btn {
  color: #ec426d;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
.partner-area .situation-text-error, .profile .situation-text-error {
  background-color: rgba(236, 66, 109, 0.2274509804);
  color: #20252a;
  padding: 12px;
  border-radius: 4px;
}
.partner-area .partner-area-content, .profile .partner-area-content {
  height: 100%;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.partner-area .partner-area-content .partner-avatar, .profile .partner-area-content .partner-avatar {
  border-radius: 8px;
  max-width: 100px;
  max-height: 100px;
  box-shadow: 0px 1px 1px 1px #d9d9d9;
  background-color: #617889;
}
.partner-area .input-wrapper, .profile .input-wrapper {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
}
.partner-area .input-wrapper button, .profile .input-wrapper button {
  margin-top: 24px;
  background-color: #1DA1F2;
  color: white;
  border-radius: 8px;
  height: 40px;
  border: none;
}
.partner-area .input-wrapper label, .profile .input-wrapper label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.partner-area .input-wrapper label input, .profile .input-wrapper label input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  border: 2px solid #E1E8ED;
  padding: 0px 8px;
  color: #14171A;
}
.partner-area .input-wrapper label select, .profile .input-wrapper label select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  border: 2px solid #E1E8ED;
  padding: 0px 8px;
  color: #14171A;
}
.partner-area .input-wrapper label p, .profile .input-wrapper label p {
  margin: 0px;
  padding: 0px;
  color: #0D2C77;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 1.2px;
  font-weight: 600;
}

.accredited {
  min-width: 100vw;
  min-height: 100vh;
}
.accredited .left-content {
  background-color: #1DA1F2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accredited .left-content .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.accredited .left-content .phrase-container {
  max-width: 60%;
  height: 35vh;
  align-self: center;
  display: flex;
  margin-top: 15vh;
}
.accredited .left-content .phrase-container h1 {
  font-family: "Poppins", "open-sans";
  font-size: 57px;
  line-height: 67px;
  color: white;
  text-align: start;
}
@media screen and (max-width: 1600px) {
  .accredited .left-content .phrase-container h1 {
    font-family: "Poppins", "open-sans";
    font-size: 48px;
    line-height: 58px;
    color: white;
  }
}
.accredited .left-content .background-img-container {
  padding: 0px;
  margin: 0px;
  max-height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.accredited .left-content .background-img-container img {
  overflow: hidden !important;
  max-width: 100%;
  max-height: 100%;
  bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  left: auto;
  right: auto;
}
@media screen and (max-width: 1300px) {
  .accredited .left-content {
    position: relative !important;
  }
}
.accredited .left {
  overflow: scroll;
  max-height: 100vh;
  background-color: white;
  min-height: 100vh;
}
.accredited .left .header {
  display: flex;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 0.5px solid #f5f5f5;
  position: relative;
}
.accredited .left .header button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
}
.accredited .left .header svg {
  max-width: 100px;
  align-self: center;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
}
.accredited .left .avatar-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.accredited .left .avatar-wrapper p {
  margin: 0;
  padding: 0;
}
.accredited .left .avatar-wrapper .professional-name {
  font-weight: 600;
}
.accredited .left .avatar-wrapper .professional-council {
  font-weight: 400;
  font-size: 12px;
}
.accredited .left .avatar-wrapper .professional-categories {
  margin-top: 8px;
  color: #1DA1F2;
}
.accredited .left .avatar-wrapper img {
  padding: 1px;
  max-width: 50%;
  max-height: 200px;
  box-shadow: 0px 0px 1px 1px #e0e0e0;
  background-color: rgba(102, 102, 102, 0.4);
  border-radius: 4px;
  object-fit: fill;
  margin-bottom: 8px;
}
.accredited .left section {
  padding: 32px;
}
.accredited .left section .section-title {
  font-size: 16px;
}
.accredited .left section .services-description {
  font-weight: 300;
}
.accredited .left section .text-discount {
  font-size: 12px;
  color: orange;
}
.accredited .left section .benefit-wrapper {
  margin-bottom: 16px;
  box-shadow: 0px 0px 1px 1px #f5f5f5;
  padding: 8px 4px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accredited .left section .benefit-wrapper p {
  margin: 0;
  padding: 0;
}
.accredited .left section .benefit-wrapper .benefit-title {
  margin-bottom: 8px !important;
  color: #5a6ff4 !important;
}
.accredited .left section .benefit-wrapper .prices p {
  font-size: 10px;
}
.accredited .left section .benefit-wrapper .prices .normal-price {
  margin-right: 16px;
}
.accredited .left section .benefit-wrapper .prices .text-price {
  font-size: 16px;
}
.accredited .left section .benefit-wrapper .prices .price-description {
  font-weight: bold;
  color: #646464;
}
.accredited .left section .address-wrapper {
  margin-bottom: 16px;
  box-shadow: 0px 0px 1px 1px #f5f5f5;
  padding: 8px 4px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.accredited .left section .address-wrapper .address-avatar {
  margin-bottom: 8px;
}
.accredited .left section .address-wrapper .address-avatar img {
  max-width: 64px;
  max-height: 64px;
  border-radius: 4px;
}
.accredited .left section .address-wrapper span {
  margin-right: 8px;
}
.accredited .left section .address-wrapper p {
  margin: 0;
}
.accredited .left section .address-wrapper .address-worker-time {
  margin-top: 8px;
  font-size: 10px;
  letter-spacing: 1.2px;
  margin-left: 8px;
}
.accredited .left section .address-wrapper .address-worker-time p {
  margin-top: 4px;
}
.accredited .left section .address-wrapper .address-worker-time span {
  margin-left: 12px;
}
.accredited .left section .address-wrapper .address-phones {
  margin-top: 8px;
  font-size: 10px;
  letter-spacing: 1.2px;
  margin-left: 8px;
}
@media screen and (max-width: 500px) {
  .accredited .benefit-title {
    margin-bottom: 8px !important;
    color: #5a6ff4 !important;
  }
  .accredited .header {
    padding: 12px 0px !important;
  }
  .accredited .benefit-wrapper {
    flex-direction: column;
  }
}

.lead-header {
  padding: 32px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lead-header .cta-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.lead-header .cta-btn-wrapper p {
  margin: 0px;
  padding: 0px;
}
.lead-header .cta-btn-wrapper button {
  margin-left: 24px;
  background-color: #1DA1F2;
  border: none;
  height: 44px;
  padding: 0px 24px;
  border-radius: 44px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lead-header .cta-btn-wrapper button svg {
  margin-left: 8px;
}

.select-product-header {
  margin: 24px 0px 32px 0px;
  display: flex;
  justify-content: center;
}
.select-product-header h3 {
  text-align: center;
}
.select-product-header ::after {
  width: 100%;
  height: 4px;
  width: 24px;
  content: "";
  display: flex;
  align-self: flex-end;
  background-color: #1DA1F2;
}

.select-product-cta {
  background-color: orange;
  border: none;
  min-width: 100%;
  padding: 9px 16px;
  color: white;
  font-weight: bold;
  box-shadow: 1px 1px 10px 5px rgb(235, 221, 196);
}

.lead-form .title-wrapper {
  display: flex;
  justify-content: space-between;
}
.lead-form .title-wrapper p {
  font-weight: bold;
}
.lead-form .title-wrapper span {
  color: orange;
}
.lead-form .input-wrapper-2 {
  margin: 24px 0px;
}
.lead-form .option-wrapper-2 {
  margin: 0px 0px;
}
.lead-form .double-input .input-wrapper-2 {
  margin: 0px 0px;
}

html, body {
  box-sizing: border-box;
  font-family: "Poppins", "cursive";
}

* {
  padding: 0px;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", "cursive" !important;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}


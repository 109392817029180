.lead-header {
    padding: 32px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cta-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin: 0px;
            padding: 0px;
        }

        button {
            margin-left: 24px;
            background-color: $primary;
            border: none;
            height: 44px;
            padding: 0px 24px;
            border-radius: 44px;
            color: white;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 8px;
            }
        }
    }
}

.select-product-header {
    margin: 24px 0px 32px 0px;
    display: flex;
    justify-content: center;
    h3 {
        text-align: center;
    }

    ::after {
        width: 100%;
        height: 4px;
        width: 24px;
        content: '';
        display: flex;
        align-self: flex-end;
        background-color: #1DA1F2;
    }

}

.select-product-cta {
    background-color: orange;
    border: none;
    min-width: 100%;
    padding: 9px 16px;
    color: white;
    font-weight: bold;
    box-shadow: 1px 1px 10px 5px rgb(235, 221, 196);
}

.lead-form {
    .title-wrapper {
        p {
            font-weight: bold;
        }

        span {
            color: orange;
        }

        display: flex;
        justify-content: space-between;
    }

    .input-wrapper-2 {
        margin: 24px 0px;
    }

    .option-wrapper-2 {
        margin: 0px 0px;
    }

    .double-input {
        .input-wrapper-2 {
            margin: 0px 0px;
        }
    }
}
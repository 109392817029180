@import './colors.scss';

.product {
    min-height: 100vh;
    background-color: white;

    a {
        color: #459CF9;
    }

    .product-type-wrapper {
        display: flex;
        align-items: center;
        border-bottom: -1px solid #aaafac;
        max-width: 70%;
        
        span {
            position: relative;
            padding: 24px 0px 12px 0px;
            display: flex;
            align-items: center;
            font-weight: 600;
            margin-right: 24px;
            svg {
                margin-right: 4px;
            }

            .soon {
                position: absolute;
                top: -4px;
                right: 0px;
                margin: auto;
                background-color: #FFFBD2;
                text-align: center;
                border-radius: 4px;
                font-size: 8px;
                text-transform: uppercase;
                width: fit-content;
                padding: 2px 8px;
                font-weight: bold;
                z-index: 2;
            }
        }

        .checked {
           border-bottom: 2px solid $primary;
        }
        
        @media screen and (max-width: 440px) {
            span {
                position: relative;
                padding: 24px 0px 12px 0px;
                display: flex;
                align-items: center;
                font-weight: bold;
                margin-right: 24px;
                font-size: 12px;
                svg {
                    margin-right: 4px;
                }
        }
    }

    }

    .product-description-wrapper {
        padding: 32px 0px;
        h5 {
            color: $tertiary;
            font-size: 26px;
            line-height: 42px;
            letter-spacing: 0.56px;
            font-weight: 600;
            max-width: 70%;
            margin-bottom: 32px;
            
        }

        .product-description-list {
            .product-description {
                margin-bottom: 24px;
                display: flex;
                align-items: center;

                svg {
                   min-width: 24px;
                   max-width: 24px;
                }
                
                p {
                    margin: 0px;
                    padding: 0px;
                    margin-left: 24px;
                    max-width: 45ch;
                    color: #5A5A71;
                }
            }
        }
    }
    

    .product-price-card-container {
        font-family: $font-secondary !important;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}
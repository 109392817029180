@import './colors.scss';
@import './fonts.scss';

.work-with-us-container {
    font-family: $font-secondary;
    min-height: 200vh;
    min-width: 200vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12000;
    overflow: auto;
    .col-left {
        height: 100vh;
        min-width: 100vw;
        position: absolute;
        background-color: rgba(0,0,0, .5);
        overflow: scroll;
    }

    .col-right {
        box-sizing: border-box;
        position: fixed;
        background-color: #FBFBFB;
        animation: .25s col-right ease-in-out;
        width: 30vw;
        right: 0;
        top: 0;
        height: 100%;
        overflow: scroll;
        z-index: 120000;
        padding: 64px 24px;

        .close-wrapper {
            display: flex;
            justify-content: flex-end;

            button {
                border: none;
                outline: none;
                background-color: transparent;
                font-weight: bold;
            }
        }

        .title-wrapper {
            display: flex;
            align-items: flex-start;
    
    
            h1 {
                font-size: 24px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                margin-bottom: 12px;
                width: 90%;
                color: $tertiary;
                    }
    
    
        }
    
        p {
            font-weight: 500;
            font-size: 16px;
            color: #2D3240;
            padding: 0px;
            margin: 0px;
        }
    
        .input-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            label {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 24px;
    
                input {
                    width: 100%;
                    height: 40px;
                    border-radius: 4px;
    
                    outline: none;
                    background-color: transparent;
                    border: 2px solid #E1E8ED;
                    padding: 0px 8px;
                    color: $tertiary;
                }
    
                p {
                    margin: 0px;
                    padding: 0px;
                    color: #0D2C77;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-bottom: 8px;
                    letter-spacing: 1.2px;
                    font-weight: 600;
    
                }
            }

            .lead-submit {
                margin-top: 24px;
                width: 100%;
                height: 44px !important;
                border-radius: 100px;
                background-color: $primary;
                color: white;
                border: none;
        
                font-weight: 600;
            }
        }


        @media screen and (max-width: 1200px) {
            width: 90vw;
        }

        @media screen and (max-height: 800px) {
            padding-top: 120px;
        }

        @media screen and (max-height: 721px) {
            padding-top: 200px;
        }
        @media screen and (max-height: 645px) {
            padding-top: 280px;
        }

        @media screen and (max-height: 554px) {
            padding-top: 60vh;
            .lead-submit {
                font-size: 12px;
            }
        }

        @media screen and (max-height: 554px) {
            padding-top: 80vh;
        }

    }


}

@keyframes col-right {
    from {
        transform: translateX(100vw);
    }
    to {
        transform: translateX(0px);
    }
}
@import './colors.scss';

.find-benefits {
    height: 100vh;

    .logo-mobile {
        background-color: #6E5AED;
        svg {
            max-width: 50%;
        }
    }

    .left-content {
        background-color: $primary; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
        }
        
        .phrase-container {
            max-width: 60%;
            height: 35vh;
            align-self: center;
            display: flex;
            margin-top: 15vh;
            h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 57px;
                line-height: 67px;
                color: white;
                text-align: start;
            }

            @media screen and (max-width: 1600px){
                h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 48px;
                line-height: 58px;
                color: white;
                }
            }
        }

        .background-img-container {
            padding: 0px;
            margin: 0px;
            max-height: 40vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                overflow: hidden !important;
                max-width: 100%;
                max-height: 100%;
                bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;
                left: auto;
                right: auto;
            }
        }

        @media screen and (max-width: 1300px) {
            position: relative !important;
        }

    }

    .right-content {
        padding: 32px 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;

        .choose-type {
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
                padding: 8px 8px;
                min-width: 80%;
                border-radius: 100px;
                margin: 4px 0px;
                background-color: transparent;
                border: 1px solid #212121;
            }
        }

        .header {
            border-bottom: 1px solid #f5f5f5;
            margin-bottom: 24px;
            padding: 0px 32px;
            display: flex;
            align-items: center;


            .name {
                color: #212121;
                font-weight: bold;
            }
            
            p {
                font-size: 14px;
            }

            span {
                color: #4264fb;
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .search-professional-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                align-items: center;
                justify-content: center;
                background-color: transparent;
                display: flex;
                border-radius: 100px;
                padding: 4px 16px;
                border: 1px solid #212121;

                span {
                    margin-right: 8px;
                    font-size: 20px;
                }
            }
        }

        .filter-box-wrapper {
            padding: 0px 32px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 64px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            font-size: 12px;
            font-weight: 600;
            font-family: 'Lato', 'open-sans';

            .filter-box {
                margin-bottom: 4px;
                cursor: pointer;
                margin-right: 8px;
                cursor: pointer;

                p {
                    padding: 0px;
                    margin: 0px;
                }
                padding: 4px 16px;
                display: flex;
                align-items: center;
                border: 1px solid $tertiary;
                border-radius: 100px;
                justify-content: center;
                color: $tertiary;
            }

            .filter-box-active {
                margin-bottom: 4px;
                margin-right: 8px;
                cursor: pointer;
                p {
                    padding: 0px;
                    margin: 0px;
                }
                padding: 4px 16px;
                display: flex;
                align-items: center;
                background-color: $tertiary;
                justify-content: center;
                border: 1px solid $tertiary;
                border-radius: 100px;
                color: white;
            }


        }


        .all-professionals-filter {
            .filter-box-wrapper {
                padding: 0px 32px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 24px;
                text-transform: uppercase;
                letter-spacing: 1.2px;
                font-size: 12px;
                font-weight: 600;
                font-family: 'Lato', 'open-sans';

                span {
                    margin-right: 8px;
                }

                p {
                    padding: 0px;
                    margin: 0px;
                }
    
                .filter-box {
                    margin-bottom: 4px;
                    cursor: pointer;
                    margin-right: 8px;
                    padding: 4px 16px;
                    display: flex;
                    align-items: center;
                    border: 1px solid $tertiary;
                    border-radius: 100px;
                    justify-content: center;
                    color: $tertiary;
                }
    
                .filter-box-active {
                    margin-bottom: 4px;
                    margin-right: 8px;
                    cursor: pointer;
                    p {
                        padding: 0px;
                        margin: 0px;
                    }
                    padding: 4px 16px;
                    display: flex;
                    align-items: center;
                    background-color: $tertiary;
                    justify-content: center;
                    border: 1px solid $tertiary;
                    border-radius: 100px;
                    color: white;
                }
    
    
            }
        }

    
        .partners-wrapper {
            padding: 0px 32px;

            .professionals-details-wrapper {
                display: flex;
                border: 1px solid #c9c9c9;
                border-radius: 8px;
                align-items: center;
                padding: 8px;
                margin-bottom: 8px;
                img {
                    max-width: 48px;
                    max-height: 48px;
                    margin-right: 24px;
                }

                .professional-detail-body {

                    .professional-name {
                        font-weight: 600;
                    }

                    .professional-council {
                        font-size: 12px;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            .partner-card {
                max-width: 400px;
                max-height: 300px;
                border-radius: 8px;
                .partner-card-avatar {
                    background-color: $background5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 200px;
                    box-shadow: 0px 0px 1px 1px #e0e0e0;
                    border-radius: 4px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 4px;
                        box-shadow: 0px 0px 1px 1px #e0e0e0;
                        background-color: #f9f9f9;
                    }

                }

                .partner-card-body {
                    padding: 16px 16px;
                    color: #212121;
                    font-weight: 400;
                    font-size: 16px;

                    p {
                        font-weight: 600;
                        font-size: 18px;
                    }

                    .partner-address {
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: 8px;
                            max-width: 24px;
                        }
                    }

                    .partner-contact {
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                        svg {
                            margin-left: 4px;
                            margin-right: 8px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                    
                }

                .partner-card-footer {
                    padding: 24px;
                    display: flex;
                    justify-content: flex-end;

                    button {
                        background-color: transparent;
                        padding: 8px 16px;
                        border-radius: 8px;
                        color: #4264fb;;
                        border: none;
                        outline: none;
                    }
                }

            }
        }

        .filter-box-mobile-wrapper {
            margin-bottom: 32px;
            padding: 0px 32px;
            display: none;
            .btn-wrapper {
                display: flex;
                align-items: center;
                p {
                    margin: 0;
                    padding: 0;
                }

                button {
                    margin-left: 8px;
                    border-radius: 100px;
                    border: 1px solid #6E5AED;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #6E5AED;
                    padding: 2px 16px;
                    box-shadow: 0px 0px 2px 2px #f9f9f9;
                }
            }
        }

        @media screen and (max-width: 1920px) {
            .filter-box-wrapper {
             display: none;

            }

            .filter-box-mobile-wrapper {
                display: block;
            }

            .all-professionals-filter {
                .filter-box-wrapper {
                    display: none;
                   }
       
                   .filter-box-mobile-wrapper {
                       display: block;
                   }
            }
        }
    }
}

.mobile-hidden-categories {
    z-index: 9999;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,.2);

    .background {
        width: 100vw;
        height: 100vh;
        border: none;
        background-color: transparent;
    }

    .hidden-menu-category {
        background-color: rgb(0, 15, 64);
        width: 100vw;
        max-height: 100vh;
        overflow: scroll;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        animation: categories-menu .400s ease-in-out;

        .item-wrapper {
            padding: 16px 16px;
            border-bottom: 1px solid #c4c4c4;

            button {
                background-color: transparent;
                border: none;
                outline: none;
                color:  rgb(171, 230, 255);
            }
        }
    }

}

@keyframes categories-menu {
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0vh);
    }
}

.benefit-btn-select {
    background-color: $primary;
    border-radius: 100px;
    padding: 9px 16px;
    width: 100%;
    max-width: 300px;
    border: none;
    color: #f5f5f5;
    font-weight: 600;
}

.benefit-back-btn {
    background-color: transparent;
    border: none;
    span {
        margin-right: 8px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
}
.hero {
    min-height: 85vh;

    .hero-title-wrapper {
        h1 {
        font-size: 62px;
        line-height: 72px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: 0px;
        width: 90%;
        color: #00004D;

        span {
            color: #3F8AF8;
            }
        }

        p {
        margin-top: 40px;
        margin-bottom: 40px;
        color: rgb(7, 8, 23);
        width: 70%;
        font-size: 18px;
        font-family: Lato, sans-serif;
        font-weight: 400;
        line-height: 26px;
        }

        @media screen and (max-width: 600px) {
            h1 {
                font-size: 38px !important;
                line-height: 48px !important;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                margin-bottom: 12px;
                margin-top: 54px;
                color: #00004D !important;
                text-align: center;
            }

            p {
                margin: 54px 0px 12px 0px;
                text-align: center;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            
          }

    }

    .hero-img-wrapper {
        background-image: url('../images/background-img.svg');
        background-size: 100% no-repeat;
        img {
            width: 100%;
        }
    }
}

.hero2 {
    height: 85vh;

    @media screen and (max-width: 400px) {
        h1 {
            font-size: 32px !important;
            line-height: 48px !important;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            margin-bottom: 12px;
            margin-top: 54px;
            color: rgb(7, 8, 23);
        }
      }

   h1 {
       font-size: 44px;
       line-height: 62px;
       font-family: 'Poppins', sans-serif;
       font-weight: 600;
       margin-bottom: 12px;
       margin-top: 54px;
       color: rgb(7, 8, 23);
    }

    h5 {
        margin-top: 12px;
        margin-bottom: 40px;
        color: rgb(7, 8, 23);
        width: 100%;
        font-size: 18px;
        font-family: Lato, sans-serif;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }

    text {
        margin-top: 12px;
        margin-bottom: 40px;
        color: rgb(7, 8, 23);
        width: 70%;
        font-size: 18px;
        font-family: Lato, sans-serif;
        font-weight: 400;
        line-height: 26px;
    }

    .title-wrapper {
        min-height: 85%;
        padding-bottom: 64px;
    }

    .partners {
        img {
            width: 10%;
            padding-bottom: 8px;
        }

        @media screen and (max-width: 600px) {
            img {
                width: 20% !important;
                padding-bottom: 8px;
            }
        }
    }
}
@import './colors.scss';

.benefits-wrapper {
    border-radius: 8px;
    border-radius: 8px;
    padding: 24px;
    min-height: 250px;
    .benefits-icon-wrapper {
        padding: 12px;
        border-radius: 100px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        font-weight: 600;
        margin-top: 16px;
    }

    a {
        color: $primary;
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
    }

    text {
        color: #7C7A80;
    }
}

.benefits-container {

}
@import './colors.scss';
@import './fonts.scss';
@import './header-desktop.scss';
@import './header-compact.scss';
@import './mobile-menu-area.scss';
@import './buttons.scss';
@import './hero.scss';
@import './about.scss';
@import './benefits.scss';
@import './team.scss';
@import './pricing.scss';
@import './faq.scss';
@import './footer.scss';
@import './whytobepartner.scss';
@import './partners.scss';
@import './lead.scss';
@import './work-with-us.scss';
@import './find-benefits.scss';
@import './profile.scss';
@import './headerv2.scss';
@import './herov3.scss';
@import './herov4.scss';
@import './components.scss';
@import './steps.scss';
@import './banner.scss';
@import './product.scss';
@import './login.scss';
@import './partner-area.scss';
@import './accredited.scss';
@import './lead2.scss';

html, body {
    box-sizing: border-box;
    font-family: $font-secondary;
}

* {
    padding: 0px;
    margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary !important;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}



*::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 20px;
  }

  @media screen and (max-width: 768px){
    ::-webkit-scrollbar {
        display: none;
    }
  }

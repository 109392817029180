@import './colors.scss';

.profile {
    @extend .partner-area;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: 64px;

    .logo-wrapper {
        display: flex;
        justify-content: flex-start !important;
        align-items: center !important;
        width: 100%;
    }

    h4 {
        font-size: 18px;
        span {
            color: #4264fb;
        }
    }

    .card-wrapper {
        background-image: url('../images/card.svg');
        background-size: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 242px;
        max-width: 350px;
        border-radius: 20px;
        flex-direction: column;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .card-cpf {
            align-self: flex-start;
            position: absolute;
            bottom: 56px;
            left: 16px;
             p {
                color: #46E3B4;
                font-size: 12px;
                font-weight: bold;
             }
        }

        .card-name {
            width: 100%;
            position: absolute;
            bottom: 24px;
            left: 16px;
                p {
                    color: #f5f5f5;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1.2px;
                 }
        }

        .card-qr-code {
            cursor: pointer;
            position: absolute;
            right: 12px;
            bottom: 42px;
            z-index: 999;
            border-radius: 4px;
            border: 2px solid #212121;

            img {
                height: 100px;
                width: 100px;
                transition: all 0.2s ease-in-out;
            }
        }

        .card-qr-code:hover {
            cursor: pointer;
            position: absolute;
            right: -24px;
            bottom: -32px;
            z-index: 999;
            border-radius: 2px;


            img {
                height: 350px;
                width: 350px;
            }

        }
    }

    .button-secondary {
        background-color: transparent !important;
        color: #212121 !important;
        text-decoration: underline;
    }

    .partner-area-content {
        width: 100%;

        .input-wrapper {
            max-width: 400px;
            flex-direction: row;

        
        }
    }


    .logout-btn {
        background-color: transparent;
        text-decoration: none !important;
        border-radius: 4px;
        color: rgb(236, 89, 89) !important;
        padding: 4px 8px;
        border: 1px solid rgb(236, 89, 89) !important;
    }


            .user-data-card {
                padding: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .br-top {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }

                .br-bottom {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                .item-wrapper {
                    background-color: white;
                    max-width: 700px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    border-bottom: 1px solid #f5f5f5;
                    position: relative;

                    p {
                        margin: 0;
                    }

                    .title {
                        padding: 8px 16px;
                        color: #212121;
                    }

                    .description {
                        padding: 8px 16px;
                        color: #7d7d7d;
                        padding-right: 84px;
                    }

                    .edit {
                        position: absolute;
                        right: 16px;
                        display: flex;
                        top: 0;
                        bottom: 0;
                        margin-top: 0px;
                        margin-bottom: 0px;

                        button {
                            border: none;
                            color:#4264fb;
                            background-color: transparent;
                        }
                    }
                }
            }
}

.card-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        .btn-active {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $primary;
            color: $primary;
            padding: 4px 8px;
            border-radius: 2px;
            width: fit-content;
            margin-top: 12px;
        }

        .btn-inactive {
            background-color: transparent;
            border: none;
            color: 'black';
            padding: 4px 8px;
            border-radius: 2px;
            width: fit-content;
            margin-top: 12px;
        }
    }
    .card-background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        position: relative;

        .card-letters-wrapper {
            position: absolute;
            display: flex;
            width: 88%;
            height: 50%;
            bottom: 36px;
            justify-content: space-evenly;

            p {
                margin: 0px;
                padding: 0px;
                color: #f1f1f1;
                font-family: 'card-font';
                text-transform: uppercase;
                font-size: 14px;
            }

            @media screen and (max-width: 800px) {
                p {
                    font-size: 10px;
                }
            }

            .card-letters-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .card-letters-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .card-letters-title {
                font-size: 9px;
                letter-spacing: 1.2px;
                margin-bottom: 4px;
            }

            @media screen and (max-width: 800px) {
                .card-letters-title {
                    font-size: 10px;
                }
            }
        }
    
        img {
            width: 100%;
        }
}

.download-card-btn {
    background-color: $primary;
    border: none;
    color: white;
    padding: 4px 8px;
    border-radius: 2px;
    width: fit-content;
    margin-top: 12px;
    
}


}
.mobile-menu-area {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex: 1;
        z-index: 100000000;
    
        .divisor-left {
            height: 100vh;
            width: 100vw;
            background-color: rgba(0, 0, 0, .5);
        }
    
        .menu-area {
            overflow-y: scroll;
            background-color: rgb(0, 15, 64);
            padding: 24px 24px 42px 24px;
            height: fit-content;
            width: 100vw;
            animation: 0.25s menu-open ease-in-out;
            position: absolute;
            bottom: 0px;
            z-index: 9999999;
            max-height: 100vh;
        }
    
        .menu-close-btn {
            width: fit-content;
            height: fit-content;
            margin-bottom: 24px;
            background-color: transparent;
            color:  orange;
            border: none;
            font-weight: bold;
            font-size: 18px;
        }
    
        ul {
            margin-top: 24px !important;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding-left: 0px;
            margin: 0px;
            height: 100%;
            list-style-type: none;
            li {
                padding: 12px 0px;
                margin-top: 12px;
                text-decoration: none;
                text-transform: none;
                font-weight: 600;
                border-bottom: 1px solid #f5f5f5;
                color:  rgb(171, 230, 255);
                 a {
                     text-decoration: none;
                     text-transform: none;
                     color:  rgb(171, 230, 255);
                     font-weight: 700;
                     font-size: 16px;
                     line-height: 24px;
                     letter-spacing: 0.51px;
                 }
    
                 a:hover {
                     color: $btn-primary;
                 }
            }
        }


        .menu-area-footer {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    
        
    }
    
    @keyframes menu-open {
        from {
            bottom: -500px;
        }
    
        to {
           bottom: 0px;
        }
    }



// .mobile-menu-area {
//     position: fixed;
//     top: 0;
//     height: 100vh;
//     width: 100vw;
//     background-color: rgba(0, 0, 0, 0.2);
//     display: flex;
//     z-index: 10001;
//     opacity: 1;

//     .divisor-left {
//         height: 100%;
//         flex: 1;
//     }

//     .menu-area {
//         background-color: white;
//         padding: 24px;
//         height: 100%;
//         min-width: 20%;
//         animation: 0.25s menu-open;
//     }

//     .menu-close-btn {
//         width: 48px;
//         height: 48px;
//         margin-bottom: 24px;
//         background-color: transparent;
//         border: none;
//         color: $primary;
//         font-weight: bold;
//         font-size: 18px;
//     }

//     ul {
//         margin-top: 24px !important;
//         display: flex;
//         flex-direction: column;
//         align-items: stretch;
//         margin: 0px;
//         height: 100%;
//         list-style-type: none;
//         li {
//             margin-top: 12px;
//             text-decoration: none;
//             text-transform: none;
//             font-weight: 600;
//              a {
//                  text-decoration: none;
//                  text-transform: none;
//                  color: #20252a;
//                  font-weight: 700;
//                  font-size: 16px;
//                  line-height: 24px;
//                  letter-spacing: 0.51px;
//              }

//              a:hover {
//                  color: $btn-primary;
//              }
//         }
//     }

    
// }

// @keyframes menu-open {
//     from {
//         min-width: 0%;
//     }

//     to {
//         min-width: 20%;
//     }
// }



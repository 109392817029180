
.title-before::before {
    content: '';
    display: block;
    width: 32px;
    height: 6px;
    background: #7F7FD5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 100px;
    margin-bottom: 24px;
}


.title-before-center::before {
    content: '';
    display: block;
    width: 32px;
    height: 6px;
    margin: auto;
    background: #7F7FD5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 100px;
    margin-bottom: 24px;
}

.top-advisor {
    background-color: #4264fb;
    padding: 12px 0px;
    text-align: center;
    a {
        color: white;
    }
}

#map { 
    height: 180px; 
    width: 100%;
}

.partner-user-modal {
    .item-wrapper {
        .title {
            color: $primary;
            font-weight: bold;
        }
    }
}

.situation-text-ok {
    background-color: #42ec9a;
    color: #20252a;
    padding: 24px;
    border-radius: 4px;
}

.situation-text-error {
    background-color: #ec426d3a;
    color: #20252a;
    padding: 12px;
    border-radius: 4px;
}


.scanner {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999999;
    background-color: rgba(0,0,0, .8);
    height: 100vh;
    width: 100vW;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        margin-top: 24px;
        max-width: 200px;
        height: 44px;
        width: 100%;
        border-radius: 4px;
        background-color: $primary;
        color: white;
        border: none;
    }

    .scanner-bar {
        width: 100%;
        height: 100%;
        max-height: 250px;
        max-width: 250px;
        border: 1px solid orange;
    }
}

.input-wrapper-2 {
    width: 100%;
    border: .071428571428571rem solid #dcdcdc;
    padding: 12px;
    display: flex;
    position: relative;
    transition: all ease-in-out .250s;

    .text-input-error {
        position: absolute;
        bottom: -18px;
        left: 0;
        font-size: 10px;

        .material-icons-round {
            font-size: 14px;
            margin-right: 4px;
        }
    }

    .spinner-container {
        visibility: hidden;
    }

    label {
        color: #212121;
        display: flex;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        background-color: transparent;
        width: 100%;
        line-height: 1.8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    input {
        display: block;
        font-size: 1rem;
        background-color: transparent;
        color: #616161;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
    }

    input::placeholder {
        font-weight: 300;
        font-size: 12px;
        color: #696969 !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-loading {
    background-color: rgb(0, 15, 64) !important;
    position: relative;
    label {
        color: orange;
        display: flex;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        width: 100%;
        line-height: 1.8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .spinner-container {
        visibility: visible;
        position: absolute;
        right: 16px;
        top: 28px;

        .spinner-grow {
            font-size: 4px;
            width: 12px;
            height: 12px;
        }
    }
}

.input-wrapper-2:focus-within {
    background-color: rgb(0, 15, 64) !important;
    label {
        color: orange;
        display: flex;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        width: 100%;
        line-height: 1.8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    input {
        display: block;
        font-size: 1rem;
        color: #f5f5f5;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
    }
}

.option-wrapper-2 {
    width: 100%;
    border: .071428571428571rem solid #dcdcdc;
    padding: 12px;
    position: relative;
    transition: all ease-in-out .250s;
    min-height: 76.67px;

    .text-input-error {
        position: absolute;
        bottom: -18px;
        left: 0;
        font-size: 10px;

        .material-icons-round {
            font-size: 14px;
            margin-right: 4px;
        }
    }

    label {
        color: #212121;
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        background-color: transparent;
        width: 100%;
        line-height: 1.8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    input {
        display: block;
        font-size: 1rem;
        background-color: transparent;
        color: #616161;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
    }

    input::placeholder {
        font-weight: 300;
        font-size: 12px;
        color: #696969 !important;
    }

    input::placeholder {
        font-weight: 300;
        font-size: 12px;
        color: #696969 !important;
    }

    select {
        display: block;
        font-size: 1rem;
        background-color: transparent;
        color: #616161;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
        -webkit-appearance: none;
        
    }

    select::placeholder {
        font-weight: 300;
        font-size: 12px;
        color: #696969 !important;
    }

    select::placeholder {
        font-weight: 300;
        font-size: 12px;
        color: #696969 !important;
    }

    span {
        transition: all ease-in-out .250s;
        position: absolute;
        right: 8px;
        top: 28px;
        color: orange;
    }

    .hidden-content {
        visibility: hidden;
        max-height: 100vh;
        z-index: 999;
        position: absolute;
        bottom: inherit;
        left: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        opacity: 0;
        overflow: scroll;
       .hidden-option {
        background-color: rgb(0, 15, 64) !important;
        color: white;
        border: none;
        display: flex;
        visibility: visible;
        padding: 8px 24px;
        z-index: 999;
       }
    }

    .hidden-content::-webkit-scrollbar {
        width: 6px;
      }
      
      .hidden-content::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 2px;
        width: 2px;
      }

    
      @media screen and (max-width: 768px){
        .hidden-content::-webkit-scrollbar {
            display: none;
        }
      }
}


.option-wrapper-2:focus-within {
    background-color: rgb(0, 15, 64) !important;
    label {
        color: orange;
        display: flex;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        width: 100%;
        line-height: 1.8;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    input {
        display: block;
        font-size: 1rem;
        color: #f5f5f5;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
    }

    select {
        display: block;
        font-size: 1rem;
        color: #f5f5f5;
        width: 100%;
        border: none;
        outline: none;
        flex: 1;
        background-color: rgb(0, 15, 64) !important;
        color: white;
        padding: 8px;
    }

    span {
        transform: rotateZ(180deg)
    }

    .hidden-content { 
        opacity: 1;
        visibility: visible;
    }

    .hidden-content > button:focus {
        height: fit-content;
    }
}

.backward-btn {
    background-color: transparent;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin-left: 8px;
    font-weight: bold;
}

.cta-btn-lead {
    margin-top: 24px;
    background-color: orange;
    border: none;
    width: 100%;
    color: #f5f5f5;
    max-width: 500px;
    height: 64px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
}

.processing-payment {
    color: #515151;
    text-align: center;
    margin-top: 12px;
}

.total-value {
    padding: 8px;
    border: 1px solid $primary;
    p {
        text-align: center;
        margin: 0px;
        color: #212121;
        font-weight: 600;
    }
}

.btn-print {
    background-color: $primary;
    border: none;
    color: white;
    padding: 9px 16px;
    border-radius: 2px;
}


.carousel-control-next-icon {
    background-color: #d5d5d5 !important;
}

.carousel-control-prev-icon {
    background-color: #d5d5d5 !important;
}

.carousel-indicators {
    button {
        background-color: #696969 !important;
    }
    .active {
        background-color: $primary !important;
    }
}

@media screen and (max-width: 1669px) {
    .carousel-indicators {
        button {
            background-color: #d5d5d5 !important;
        }
        .active {
            background-color: #d5d5d5 !important;
        }
    }

    .carousel-control-next-icon {
        background-color: #d5d5d5 !important;
    }
    
    .carousel-control-prev-icon {
        background-color: #d5d5d5 !important;
    }
}


.clean-btn {
    background-color: transparent;
    border: none;
}

.qr-code-pix {
    text-align: center;
    font-size: 12px;
    word-wrap: break-word;
    border: 1px solid #d5d5d5;
    padding: 4px;
    border-radius: 4px;
}

.label-authorized {
    background-color: #6200ee;
    width: fit-content;
    min-width: 74px;
    padding: 4px;
    text-align: center;
    border-radius: 2px;
    color: white;
    font-size: 8px;
    text-transform: uppercase;
}

.label-ontime {
    background-color: #0067ee;
    width: fit-content;
    min-width: 74px;
    text-align: center;
    padding: 4px;
    border-radius: 2px;
    color: white;
    font-size: 8px;
    text-transform: uppercase;
}

.label-paid {
    background-color: #00ee77;
    width: fit-content;
    text-align: center;
    min-width: 74px;
    padding: 4px;
    border-radius: 2px;
    color: white;
    font-size: 8px;
    text-transform: uppercase;
}

.label-due {
    background-color: #ee004f;
    width: fit-content;
    min-width: 74px;
    padding: 4px;
    text-align: center;
    border-radius: 2px;
    color: white;
    font-size: 8px;
    text-transform: uppercase;
}

.label-due {
    background-color: #ee004f;
    width: fit-content;
    text-align: center;
    min-width: 74px;
    padding: 4px;
    border-radius: 2px;
    color: white;
    font-size: 8px;
    text-transform: uppercase;
}

.download-pdf-link {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $primary;
    font-size: 10px;
    text-transform: uppercase;
}
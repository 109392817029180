@import './colors.scss';

.faq-container {
    padding: 64px 0px;
    background-color: #0D2C77;
    .title {
        text-align: center;
        font-family: 'Poppins', 'sans-serif';
        font-size: 36px;
        line-height: 50px;
        color: white;
        font-weight: 600;
        position: relative;
        margin-bottom: 64px;
    }

    .title:before {
        content: '';
        display: block;
        margin: auto;
        height: 3px;
        width: 64px;
        background-color: $primary;
    }

    .question-wrapper {
        width: 100%;

        :nth-child(1) {
            display: flex;
            justify-content: space-between;

            p {
                text-align: center;
                font-family: 'Poppins', 'sans-serif';
                font-size: 28px;
                line-height: 50px;
                color: $background2;
                font-weight: 600;
                position: relative;
                margin-right: 8px;
                max-width: 80%;
            }

            button {
                width: 40px !important;
                height: 40px !important;
                background-color: transparent;
                border: 1.2px solid white;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }
        }

        :nth-child(2) {
              font-family: 'Poppins', 'sans-serif';
              color: #f5f5f5;
         }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: $primary;
    }
}
.steps-container-invisible {
    min-height: 35vh;
    visibility: hidden;
}


.steps-container {
    padding: 64px 0px;
    min-height: 50vh;
    visibility: visible;
    font-family: $font-secondary;
    opacity: 1;
    animation: steps-container-anim 0.600s ease-in-out;

    .steps {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    
        :nth-child(1), :nth-child(2), :nth-child(3) {
            max-width: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            :nth-child(1) {
                margin-bottom: 24px;
            }

            :nth-child(2) {
                font-weight: bold;
                text-align: center;
                color: $tertiary;
                width: 100%;
                
            }
    
            :nth-child(3) {
                text-align: justify;
                color: #5A5A71;
            }

            @media screen and (max-width: 991px) {
                max-width: 100%;
                margin-bottom: 24px;
            }
        }
    }
}


@keyframes steps-container-anim {
    from {
        opacity: 0;
        transform: translateY(200px) ;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
.partner-area {
    min-height: 100vh;

    h4 {
        color: #0e2127;
    }

    .logo-wrapper {
        min-height: 8vh;
        display: flex;
        align-items: center;
    }

    .text-accent {
        color: #617889;
        margin-top: 24px;
        max-width: 400px;
    }

    .situation-text-ok {
        background-color: #42ec9a;
        color: #20252a;
        padding: 24px;
        border-radius: 4px;
    }

    .logout-btn {
        color: #ec426d;
        background-color: transparent;
        border: none;
        text-decoration: underline;
    }

    .situation-text-error {
        background-color: #ec426d3a;
        color: #20252a;
        padding: 12px;
        border-radius: 4px;
    }


    .partner-area-content {
        height: 100%;
        min-height: 92vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .partner-avatar {
            border-radius: 8px;
            max-width: 100px;
            max-height: 100px;
            box-shadow: 0px 1px 1px 1px #d9d9d9;
            background-color: #617889;
        }


    }

    .input-wrapper {
        width: 100%;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        button {
            margin-top: 24px;
            background-color:$primary;
            color: white;
            border-radius: 8px;
            height: 40px;
            border: none;
        }

        label {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;

            input {
                width: 100%;
                height: 40px;
                border-radius: 4px;

                outline: none;
                background-color: transparent;
                border: 2px solid #E1E8ED;
                padding: 0px 8px;
                color: $tertiary;
            }

            select {
                width: 100%;
                height: 40px;
                border-radius: 4px;

                outline: none;
                background-color: transparent;
                border: 2px solid #E1E8ED;
                padding: 0px 8px;
                color: $tertiary;
            }

            p {
                margin: 0px;
                padding: 0px;
                color: #0D2C77;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 8px;
                letter-spacing: 1.2px;
                font-weight: 600;

            }
        }
    }
}
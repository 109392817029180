@import './colors.scss';

.about-wrapper {
    background-color: $tertiary;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        color: $background2;
    }

    p {
        font-size: 16px;
        color: #c4c4c4;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0px;
        margin-top: 22px;
        margin-bottom: 56px;
        max-width: 45ch !important;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        text-align: justify;
    }

    .hsmonica-partner {
        margin-top: 24px;
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 24px;

        h5 {
            font-weight: 600;
            margin-bottom: 24px;
            color: #4D6175;
        }

        img {
            max-width: 35%;
        }
    }

}

.about-misc-bg {
    position: absolute;
    top: 0; 
    bottom: 0; 
    margin-top: auto; 
    margin-bottom: auto; 
    z-index: 1;
    width: 45%;
}
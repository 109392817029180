@import './colors.scss';

.team-container {
    padding: 84px 0px;
    min-height: 75vh;
    display: flex;
    align-items: center;
    background: white;
    overflow: scroll;

    .team-wrapper {
        box-shadow: rgb(69 69 80 / 20%) 8px 8px 32px;
        background-color: rgb(255, 255, 255);
        padding: 2em;
        z-index: 2;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: stretch;
        align-items: stretch;
        text-align: center;
        font-family: Lato, sans-serif;
        font-weight: 500;
        transition: all 0.3s linear 0s;
        border-top: 5px solid rgb(255, 255, 255);
        overflow: scroll;
        max-width: 375px;
        min-width: 100%;

        .team-avatar-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 120px;
            }
        }

        h1 {
        font-family: 'Poppins', 'sans-serif' !important;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0px;
        color: rgb(0, 0, 0);
        margin-bottom: 24px;
        margin-top: 12px;
        }

        text {
            font-family: 'Lato', 'sans-serif';
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 0px;
            color: #1B1C29
        }

        p {
            text-align: center;
            font-family: 'Lato', 'sans-serif';
            font-weight: 500;
            font-weight: 300;
            color: $black3;
        }

        p:before {
            display: block;
            margin-bottom: 12px;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
        }


    }

    .advisor {
        color: orange;
        font-weight: 300;
        display: none;
    }

    @media  screen and (max-width: 500px) {
        margin-top: 64px;
        .advisor {
            display: block;
        }

    }

    @media  screen and (max-width: 350px) {
        margin-top: 120px;
    }

    
}

.scroll-1 {
    transform: translateX(-500px) !important; 
}

#team-scroll {
   overflow: scroll; 
   -webkit-scrollbar,
   -webkit-scrollbar-track {
        display: block !important;
        background-color: red;
    }
}

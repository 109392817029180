.herov3 {
    .left-content {
        flex-direction: column;
        padding-top: 8%;

        h1 {
            font-weight: 48px;
            font-family: $font-secondary;
            max-width: 480px;
            color: $tertiary;
            font-weight: bold;
        }

        h4 {
            font-size: 18px;
            color: #585858;
            line-height: 150%;
            margin: 24px 0px 42px 0px;
            font-family: $font-secondary;
            max-width: 45ch;
        }

        button {
            background-color: $primary;
            border: none;
            height: 44px;
            padding: 0px 24px;
            border-radius: 44px;
            color: #fbfbfb;
            font-weight: bold;
            max-width: fit-content;
            font-family: $font-secondary;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 8px;
            }
        }

        :nth-child(1) {
            opacity: 1;
            animation: fade-and-move .850s ease-in-out;
        }

        :nth-child(2) {
            opacity: 1;
            animation: fade-and-move .850s ease-in-out;
        }

        :nth-child(3) {
            opacity: 1;
            animation: fade-and-move .850s ease-in-out;
        }

        // @keyframes fade-and-move {
        //     from {
        //         margin-left: -100px;
        //         opacity: 0;
        //     }
        //     to {
        //         opacity: 1;
        //     }
        // }

        @media screen and (max-width: 991px) {
            height: fit-content !important;
            margin-top: 64px;
        }

    }

    .right-content {
        padding-top: 8%;
        display: flex;

        img {
            max-height: 75%;;
            animation: fade .850s ease-in-out;
        }

        @keyframes fade {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @media  screen and (max-width: 991px) {
            margin-top: 64px;
            align-items: center;
            justify-content: center;
            display: none;

            img {
                display: none;
                max-width: 100%;
            }
        }
    }

    .store-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 450px;
        width: 100%;
        button {
            margin: 0px 4px;
            background-color: transparent;
            border: none;
            outline: none;
        }
    }

    @media screen and (max-width: 990px) {
        margin-bottom: 32px;
        .store-btns {
            display: none;
            max-width: 330px;
        }
    }
}
.accredited {
    min-width: 100vw;
    min-height: 100vh;
    .left-content {
        background-color: $primary; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
        }
        
        .phrase-container {
            max-width: 60%;
            height: 35vh;
            align-self: center;
            display: flex;
            margin-top: 15vh;
            h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 57px;
                line-height: 67px;
                color: white;
                text-align: start;
            }

            @media screen and (max-width: 1600px){
                h1 {
                font-family: 'Poppins', 'open-sans';
                font-size: 48px;
                line-height: 58px;
                color: white;
                }
            }
        }

        .background-img-container {
            padding: 0px;
            margin: 0px;
            max-height: 40vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                overflow: hidden !important;
                max-width: 100%;
                max-height: 100%;
                bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;
                left: auto;
                right: auto;
            }
        }

        @media screen and (max-width: 1300px) {
            position: relative !important;
        }

    }
    .left {
        overflow: scroll;
        max-height: 100vh;
        .header {
            display: flex;
            align-items: center;
            padding: 12px 18px;
            border-bottom: 0.5px solid #f5f5f5;
            position: relative;

            button {
                display: flex;
                align-items: center;
                background-color: transparent;
                border: none;
                padding: 0;
            }


            svg {
                max-width: 100px;
                align-self: center;
                position: absolute;
                left: 0px;
                right: 0px;
                margin: auto;
            }
        }

        .avatar-wrapper {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            p {
                margin: 0;
                padding: 0;
            }

            .professional-name {
                font-weight: 600;
            }

            .professional-council {
                font-weight: 400;
                font-size: 12px;
            }

            .professional-categories {
                margin-top: 8px;
                color: $primary;
            }

            img {
                padding: 1px;
                max-width: 50%;
                max-height: 200px;
                box-shadow: 0px 0px 1px 1px #e0e0e0;
                background-color: #6666;
                border-radius: 4px;
                object-fit: fill;
                margin-bottom: 8px;
            }
        }


        section {
            padding: 32px;

            .section-title {
                font-size: 16px;
            }

            .services-description {
                font-weight: 300;
            }

            .text-discount {
                font-size: 12px;
                color: orange;
            }

            .benefit-wrapper {
                margin-bottom: 16px;
                box-shadow: 0px 0px 1px 1px #f5f5f5;
                padding: 8px 4px;
                border-radius: 2px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                

                p {
                    margin: 0;
                    padding: 0;
                }

                .benefit-title {
                    margin-bottom: 8px !important;
                    color: #5a6ff4 !important;
                }

                .prices {
                    p {
                        font-size: 10px;
                    }
                    .normal-price {
                        margin-right: 16px;
                    }

                    .text-price {
                        font-size: 16px;
                    }
                    .price-description {
                        font-weight: bold;
                        color: #646464;
                    }
                }
            }

            .address-wrapper {

                margin-bottom: 16px;
                box-shadow: 0px 0px 1px 1px #f5f5f5;
                padding: 8px 4px;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .address-avatar {
                    img {
                        max-width: 64px;
                        max-height: 64px;
                        border-radius: 4px;
                    }
                    margin-bottom: 8px;
                }


                span {
                    margin-right: 8px;
                }

                p {
                    margin: 0;
                }

                .address-worker-time {
                    margin-top: 8px;
                    font-size: 10px;
                    letter-spacing: 1.2px;
                    margin-left: 8px;

                    p {
                        margin-top: 4px;
                    }

                    span {
                        margin-left: 12px;
                    }
                }

                .address-phones {
                    margin-top: 8px;
                    font-size: 10px;
                    letter-spacing: 1.2px;
                    margin-left: 8px;
                }
            }
        }


        background-color: white;
        min-height: 100vh;
    }

    @media screen and (max-width: 500px) {

        .benefit-title {
            margin-bottom: 8px !important;
            color: #5a6ff4 !important;
        }
        .header {
            padding: 12px 0px !important;
        }
        .benefit-wrapper  {
            flex-direction: column;
        }
    }
}
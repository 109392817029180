// $primary: #39E8F7;
$primary: #1DA1F2;
$secondary: #6E5AED;

$tertiary: #14171A;


$background1: white;
$background2: #FBFBFB;
$background3: #263635;
$background4: #20252A;
$background5: #EFF4F8;
$background6: #91CFCA;

$black1: #070817;
$black2: #21232F;
$black3: #2f3243;

// $btn-primary: #39E8F7;
$btn-primary: #1DA1F2;


// $primary: #3F8AF8;
// $secondary: #6E5AED;
// $tertiary: #20252a;


// $background1: white;
// $background2: #FBFBFB;
// $background3: #2B3842;
// $background4: #20252A;
// $background5: #EFF4F8;
// $background6: #91CFCA;

// $black1: #070817;
// $black2: #21232F;
// $black3: #2f3243;

// $btn-primary: #3F8AF8;
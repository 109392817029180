.herov4 {
    @media screen and (min-width: 1200px) {
        margin-top: 100px;
        min-height: 82vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/5110-banner-desktop.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        background-clip: border-box;
        background-position-y: center;
        margin-bottom: 30px;
    }


    @media screen and (max-width: 1199px) {
        margin-top: 50px;
        min-height: 70vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/5110-banner-desktop.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        margin-bottom: 12px;
    }

    
    @media screen and (max-width: 900px) {
        min-height: 70vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/5110-banner-desktop.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        background-position-x: center;
        margin-bottom: 12px;
    }


    @media screen and (max-width: 767px) {
        min-height: 400px;
        margin-top: 52px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/5110-banner-desktop.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        background-position-x: center;
        margin-bottom: 12px;
    }

    @media screen and (max-width: 580px) {
        margin-top: 60px;
        min-height: 90vh;
        width: 100vw !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/5110-banner-mobile.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        background-position-x: center;
        margin-bottom: 42px;
    }
   
}

.inner-img {
    display: flex;
    height: fit-content;
    overflow: hidden;
    margin-bottom: 32px;

    @media screen and (max-width: 880px) {
        margin-top: 100px;
        min-height: 82vh;
        margin-bottom: 30px;
    }

    .img-item {
        img {
            width: 100vw;
            max-height: 90vh;
            min-height: 650px;
            object-fit: contain;

            @media screen and (min-width: 590px) {
                padding: 60px;
            }
        }
    }
}

.carousel {
    .indicator-active {
        background-color: orange;
        width: 48px;
        height: 4px;
        border: none;
        border-radius: 200px;
        margin: 0px 4px;
    }
    .indicator-inactive {
        background-color: #c5c5c5;
        width: 48px;
        height: 4px;
        border: none;
        border-radius: 200px;
        margin: 0px 4px;
    }
}

.carousel-right {
    z-index: 999;
    position: absolute;
    width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    
    right: 50px;
    top: 0px;
    bottom: 0px;
    background-color: rgb(0, 0, 0, 0.1);
    color: white;
    border: none;
    border-radius: 200px;
}

.carousel-left {
    z-index: 999;
    position: absolute;
    width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 200px;

    left: 50px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgb(0, 0, 0, 0.1);

    color: white;
    border: none;
}

.carousel-left:hover {
    background-color: orange;
}

.carousel-right:hover {
    background-color: orange;
}
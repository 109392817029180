@import './colors.scss';

.header-desktop-container {
    height: 15vh;
    padding-top: 31px;
    transition: all 2s;
    position: relative;
}


.header-desktop-container-relative {
    transition: all 1s;
    z-index: 10000;
    height: fit-content;
    background-color: white;
    padding: 12px 0px;
    opacity: 1;
}


.hide-menu {
    top: -500px;
    transition: all 1s;
    z-index: 10000;
    height: fit-content;
    background-color: white;
    padding: 12px 0px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.show-menu {
    top: 0;
    transition: all 0.4s ease-in-out;
}


.logo-wrapper {
    height: 100%;
    margin-right: 42px;
    cursor: pointer;
}

.nav-menu-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    ul {
        display: flex;
        align-items: center;
        margin: 0px;
        height: 100%;
        list-style-type: none;
        li {
            margin-right: 40px;
            text-decoration: none;
            text-transform: none;
            font-weight: 600;
             a {
                 text-decoration: none;
                 text-transform: none;
                 color: #20252a;
                 font-weight: 700;
                 font-size: 16px;
                 line-height: 24px;
                 letter-spacing: 0.51px;
             }

             a:hover {
                 color: $btn-primary;
             }
        }
    }
}
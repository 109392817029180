@import './colors.scss';

.btn-icon-primary {
    color: white;
    z-index: 888;
    text-align: center;
    min-width: 5rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    padding: 0px 40px;
    align-self: center;
    height: 2.7rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.26px;
    border: none;
    text-decoration: none;
    transition: all 0.4s ease 0s;
    background-color: $btn-primary;

    @media screen and (max-width: 600px){
         max-width: 200%;
         font-size: 9px;
    }

    @media  screen and (max-width: 350px) {
        height: 54px;
        font-size: 9px;
    }

    svg {
        width: 18px;
        margin-left: 8px;
    }
}
.footer-container {
    background-color: $tertiary;
    padding: 40px 0px 0px 0px;
    font-family: 'Poppins', 'open-sans';

    a {
        text-decoration: none;
        cursor: pointer;
    }

    h5 {
        color: #919DAB;
        font-size: 14px;
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    p {
        color: white;

        span {
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            font-weight: bold;
        }
    }

    .footer-address {
        background-color: #272D33;
        padding: 12px 0px 12px 0px;
        p {
            margin: 0px;
            padding: 0px;
        }
    }
}